import {createFetchActions} from '../../services/createActions'
import {get} from '../../services/createAPICall'
import {getConfigBaseUrl, getIAMBaseUrl} from '../../services/apiService'
import {getCounterpartyId} from '../../services/authService'

export const DO_LOGOUT = createFetchActions('DO_LOGOUT')
export const INITIALIZE_AMPLIFY = createFetchActions('INITIALIZE_AMPLIFY')
export const SET_USER = 'SET_USER'
export const FORCED_LOGOUT = 'FORCED_LOGOUT'
export const GET_CLIENT_CONFIGURATION = createFetchActions('GET_CLIENT_CONFIGURATION')
export const GET_TENANT_CONFIG = createFetchActions('GET_TENANT_CONFIG')
export const CLEAR_TENANT_CONFIG = createFetchActions('CLEAR_TENANT_CONFIG')

export const setUser = data => {
	return {
		type: SET_USER,
		payload: {
			data: data,
		},
	}
}

export const getCognitoClientID = (tenantCode: string) => (dispatch: any) => {
	return dispatch(get(INITIALIZE_AMPLIFY, `${getIAMBaseUrl()}/webclients/${tenantCode}`))
}

export const getTenantConfig = () => (dispatch: any) => {
	return dispatch(
		get(GET_TENANT_CONFIG, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/tenant-config`)
	)
}

export const clearTenantConfig = () => {
	return {type: CLEAR_TENANT_CONFIG}
}

export const toggleForceLogout = () => {
	return {
		type: FORCED_LOGOUT,
	}
}
