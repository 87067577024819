import {DELETE_TRADE, GET_TRADES} from './TradeBlotterActions'

export interface TradeBlotter {
	allTrades: any[]
	tradeDeleted: boolean
}

const initialState: TradeBlotter = {
	allTrades: null,
	tradeDeleted: null,
}

export function tradeBlotterReducer(state = initialState, action: any) {
	switch (action.type) {
		case DELETE_TRADE.triggered:
			return {...state, tradeDeleted: null}
		case DELETE_TRADE.succeeded:
			return {...state, tradeDeleted: true}
		case DELETE_TRADE.failed:
			return {...state, tradeDeleted: false}

		case GET_TRADES.triggered:
			return {...state, allTrades: null}
		case GET_TRADES.succeeded:
			return {...state, allTrades: action.response.data}
		case GET_TRADES.failed:
			return {...state, allTrades: []}
	}

	return state
}
