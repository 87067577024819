import gql from 'graphql-tag'

export const avnDocumentFragment = gql`
	fragment avnDocument on AvailabilitiesNeedsDocument {
		id
		counterPartyName
		originalFilename
		type
		match
		potentialMatch
		notional
		totalAvailableNotional
		rank
		matchedNotional
		matchedAgainstCptyAvailability
		optimalMatchNotional
		matchedAgainstNeeds
		cashPercent
		nonCashPercent
		createdDate
		collateralType
	}
`
export const avnActivitiesFragment = gql`
	fragment avnActivities on AvailabilitiesNeedsOrderEntry {
		orderId
		counterParty
		counterPartyTenantId
		isOwner
		securityId
		securityPrice {
			amount
			currency
		}
		exchangeRate
		availableQuantity
		original
		allocated
		allocatedNotional
		rebateRate
		feeRate
		name
		isin
		needQuantity
		needsNotional
		status
		collateralType
		actions
	}
`

export const avnIncomingOrdeDetailsFragment = gql`
	fragment avnIncomingOrderDetails on AvailNeedsIncomingOrderDetails {
		orderId
		counterParty
		counterPartyTenantId
		isOwner
		securityId
		securityPrice {
			amount
			currency
		}
		exchangeRate
		availableQuantity
		allocated
		allocatedNotional
		original
		rebateRate
		feeRate
		name
		isin
		needQuantity
		needsNotional
		status
		collateralType
		actions
		sodAvailableQuantity
		assetUtilizationPercent
		assetUtilizationImpactPercent
		lendingTargetQuantity
		currentAvailableQuantity
	}
`

export const getBasketDetailsQuery = gql`
	{
		availabilitiesNeeds {
			algorithm
			userAvnActivities {
				open {
					...avnDocument
				}
				outgoing {
					counterPartyName
					matchedNotional
					totalAvailableNotional
					matchedAgainstCptyAvailability
					matchedAgainstTotalNeeds
					orderEntries {
						...avnActivities
					}
				}
			}
			firmAvnActivities {
				incoming {
					counterPartyName
					orderDetails {
						...avnIncomingOrderDetails
					}
				}
				outgoing {
					totalAvailableNotional
					counterPartyName
					matchedNotional
					matchedAgainstCptyAvailability
					matchedAgainstTotalNeeds
					orderEntries {
						...avnActivities
					}
				}
			}
		}
	}
	${avnDocumentFragment}
	${avnActivitiesFragment}
	${avnIncomingOrdeDetailsFragment}
`

export const getPbQuery = gql`
	query {
		pBAxeDocuments {
			documentId
			tenantId
			groupId
			originalFilename
			remoteFilename
			uploadedDate
			counterParty
			counterPartyName
			type
			rank
			isError
			tempLocation
			createdDate
			errorList
			entries {
				pbAxeDocumentEntryId
				bbgTicker
				qty
				sedol
				cusip
				allocated
				rate
				error
			}
		}
	}
`

export const getSwapQuery = gql`
	query {
		swapDocuments {
			documentId
			tenantId
			groupId
			originalFilename
			remoteFilename
			uploadedDate
			counterParty
			counterPartyName
			type
			rank
			isError
			tempLocation
			createdDate
			errorList
			entries {
				swapDocumentEntryId
				ticker
				quantity
				sedol
				allocated
				spread
				direction
				instrumentCcy
				settlementCcy
				benchmarkRate
				cashNeutral
				error
			}
		}
	}
`

export const requestLoanDetailsQuery = (id = '') => gql`
{
	availNeedsOpenDetails(id: "${id}") {
    id
    name
    securityId
	price
	exchangeRate
    collateralType
	counterPartyQuantity
	counterPartyNotional
	needsQuantity
	needsNotional
    allocatedQuantity
    allocatedNotional
    isin
    rebateRate
    currency
    feeRate
  }
}
`

export const getDropdownDataQuery = (type = '') => gql`
query {
  availAndNeedDropDowns(type:${type}){
    assetTypes
    assetSubTypes
    indices
    funds
    ratings
    sectors
  }
}
`

export const getBasketFileDataQuery = () => gql`
	query {
		availabilitiesNeedsBaskets {
			availNeedBasketId
			name
			currency
			assetTypes
			type
			notional
			totalQuantity
			availNeedBasketItems {
				availNeedBasketItemId
				securityId
				price
				needQuantity
				notional
				isin
				feeRate
				rebateRate
			}
		}
	}
`

export const getBasketOverviewQuery = () => gql`
	query {
		portfolio {
			overView {
				availabilities {
					amount
					currency
				}
				needs {
					amount
					currency
				}
			}
		}
	}
`

export const activityLogQuery = (counterParty: string, way: string, view: String) => gql`
	query{
		availNeedOrderLogs(counterParty:"${counterParty}",way:${way}, view:${view}){
		description
		level
		direction
		createdDate
    	}
    }
`

export const fetchSearchSecurityResultQuery = security => {
	return gql`
		query {
			searchAvailabilitySecurityResults(
				searchSecurity: {
					searchSecurityEntry: [ 
            ${security}
					]
				}
			) {
				securityId
				  requestedQuantity
        	totalAvailableQuantity
          approvedQuantity
          locateRequestId
          locateRequestedBy
				availabilitiesNeedsDocumentSearchEntries {
          securityId
					availableQuantity
					rebateRate
					feeRate
					counterPartyName
					documentId
					documentEntryId
          clientRank
          approvedQuantity
          filledFlag
          shortFall
          locateRequestId
          id
          name
          price
          exchangeRate
          counterPartyQuantity
          counterPartyNotional
          needsQuantity
          needsNotional
          allocatedQuantity
          allocatedNotional
          isin
          currency
          collateralType
          documentIdForCreateBorrow
				}
			}
		}
	`
}
