import {getPMSClient, getMPSClient} from '../..'
import {createFetchActions} from '../../services/createActions'
import {put, get} from '../../services/createAPICall'
import {
	dashboardPortfolioAnalyticsQuery,
	tradingQueueSummaryQuery,
	allTradesQuery,
	getEntitiesQuery,
	marketTradeDataQuery,
} from './dashboardQueries'
import {getNotificationBaseUrl, getPMSGraphBaseUrl, getPMSBaseUrl, getPBMSBaseUrl} from '../../services/apiService'
import {getTimeZone} from '../../services/clientConfigurationService'
import {getAuthToken} from '../../services/authService'
import * as axios from 'axios'
import {toggleBlankValue} from './dashboardHelper'
import {
	SET_SBL_CLICKED_PORTFOLIO_ENTITY,
	SET_SBL_CLICKED_GROUPBY_ENTITY,
	SET_SBL_TOGGLE_INDEX,
	SET_SBL_DRILLDOWNN,
} from '../../pages/sblDashboard/sblActions'
import {
	SET_REPO_CLICKED_PORTFOLIO_ENTITY,
	SET_REPO_CLICKED_GROUPBY_ENTITY,
	SET_REPO_TOGGLE_INDEX,
	SET_REPO_DRILLDOWNN,
} from '../../pages/repo/repoActions'
import {SET_CLICKED_GROUPBY_ENTITY, SET_TOGGLE_INDEX} from '../../pages/dashboard/dashboardActions'
export const GET_NOTIFICATIONS = createFetchActions('GET_NOTIFICATIONS')
export const SET_CLICKED_PORTFOLIO_ENTITY = 'SET_CLICKED_PORTFOLIO_ENTITY'
export const SET_DASHBOARD_CLICKED_GROUPBY_ENTITY = 'SET_DASHBOARD_CLICKED_GROUPBY_ENTITY'
export const SET_DASHBOARD_TOGGLE_INDEX = 'SET_DASHBOARD_TOGGLE_INDEX'
export const SET_DRILLDOWNN = 'SET_DRILLDOWNN'
export const SET_CLICKED_FIRST_LEVEL_TREEMAP_VALUE = 'SET_CLICKED_FIRST_LEVEL_TREEMAP_VALUE'
export const SET_CLICKED_SECOND_LEVEL_TREEMAP_VALUE = 'SET_CLICKED_SECOND_LEVEL_TREEMAP_VALUE'
export const GET_CLICKED_PORTFOLIO_ENTITY = createFetchActions('GET_CLICKED_PORTFOLIO_ENTITY')
export const GET_CLICKED_GROUPBY_ENTITY = createFetchActions('GET_CLICKED_GROUPBY_ENTITY')
export const RESET_DASHBOARD_DATA = 'RESET_DASHBOARD_DATA'
export const GET_COMMON_DASHBOARD_ENTITIES_AMOUNT = createFetchActions('GET_COMMON_DASHBOARD_ENTITIES_AMOUNT')
export const GET_DASHBOARD_PORTFOLIO_ANALYTICS = createFetchActions('GET_DASHBOARD_PORTFOLIO_ANALYTICS')
export const GET_PORTFOLIO_UPLOADED_DATE = createFetchActions('GET_PORTFOLIO_UPLOADED_DATE')
export const GET_PORTFOLIO_DOCS_INCLUDED = createFetchActions('GET_PORTFOLIO_DOCS_INCLUDED')
export const SET_TRADE_BY = createFetchActions('SET_TRADE_BY')
export const GET_ALL_TRADES_FOR_DASHBOARD = createFetchActions('GET_ALL_TRADES_FOR_DASHBOARD')
export const HEATMAP_REPO_MATURE_DATA = createFetchActions('HEATMAP_REPO_MATURE_DATA')
export const HEATMAP_MATURE_DATA = createFetchActions('HEATMAP_MATURE_DATA')
export const SET_SCREEN_TYPE = createFetchActions('SET_SCREEN_TYPE')
export const FETCH_COLLATERAL_OPTIMIZATION_ENTITY_DATA = createFetchActions('FETCH_COLLATERAL_OPTIMIZATION_ENTITY_DATA')
export const FETCH_COLLATERAL_OPTIMIZATION_SUMMARY_DATA = createFetchActions(
	'FETCH_COLLATERAL_OPTIMIZATION_SUMMARY_DATA'
)
export const GET_MARKET_TRADE_DATA_FROM_DASHBOARD = createFetchActions('GET_MARKET_TRADE_DATA_FROM_DASHBOARD')

export const getMarketTradeDataFromDashboard = () => {
	return function (dispatch) {
		dispatch({type: GET_MARKET_TRADE_DATA_FROM_DASHBOARD.triggered})
		getMPSClient()
			.query({
				query: marketTradeDataQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_MARKET_TRADE_DATA_FROM_DASHBOARD.succeeded,
					response,
				})
			})
	}
}

export const setScreenType = screenType => {
	return {
		type: SET_SCREEN_TYPE,
		response: {
			screenType,
		},
	}
}

export const setClickedPortfolioEntity = (data, screenType) => {
	const actionType =
		screenType === 'repo'
			? SET_REPO_CLICKED_PORTFOLIO_ENTITY
			: screenType === 'sbl'
			? SET_SBL_CLICKED_PORTFOLIO_ENTITY
			: SET_CLICKED_PORTFOLIO_ENTITY
	return {
		type: actionType,
		response: {
			data: data,
		},
	}
}

export const setClickedFirstLevelTreemapValue = data => {
	return {
		type: SET_CLICKED_FIRST_LEVEL_TREEMAP_VALUE,
		response: {
			data: data,
		},
	}
}

export const setClickedSecondLevelTreemapValue = data => {
	return {
		type: SET_CLICKED_SECOND_LEVEL_TREEMAP_VALUE,
		response: {
			data: data,
		},
	}
}

export const setClickedGroupByEntity = (name, groupOne, groupTwo, screenType?: any) => {
	const actionType =
		screenType === 'repo'
			? SET_REPO_CLICKED_GROUPBY_ENTITY
			: screenType === 'sbl'
			? SET_SBL_CLICKED_GROUPBY_ENTITY
			: SET_CLICKED_GROUPBY_ENTITY
	return {
		type: actionType,
		response: {
			groupEntityname: name,
			groupOne,
			groupTwo,
		},
	}
}

export const setToggleActiveIndex = (name: string, index: number, screenType?: any) => {
	const actionType =
		screenType === 'repo' ? SET_REPO_TOGGLE_INDEX : screenType === 'sbl' ? SET_SBL_TOGGLE_INDEX : SET_TOGGLE_INDEX
	return {
		type: actionType,
		response: {
			toggleName: name,
			index,
		},
	}
}

export const setDrilldown = (name?: string, drilldownData?: Object, screenType?: any) => {
	const actionType =
		screenType === 'repo' ? SET_REPO_DRILLDOWNN : screenType === 'sbl' ? SET_SBL_DRILLDOWNN : SET_DRILLDOWNN
	return {
		type: actionType,
		response: {
			drilldownName: name,
			drilldownData,
		},
	}
}

export const getCollateralOptimizationEntities =
	(recompute = false, data) =>
	(dispatch: any) => {
		return dispatch(
			put(
				FETCH_COLLATERAL_OPTIMIZATION_ENTITY_DATA,
				`${getPMSBaseUrl()}/v1/decisionMaking`,
				{
					params: {
						objective: [],
						strategy: 'Collateral Optimization',
					},
					saveParams:
						data.hasOwnProperty('save') === true
							? {save: data.save, name: data.name, user: data.user, category: data.category}
							: {},
					getParams: {
						get: false,
						optimizationId: '',
						name: '',
						user: '',
						category: '',
					},

					requestType: 'overview',
					recompute,
					includeBusiness: data.selectedOptions || [],
				},

				null
			)
		)
	}

export function getDashboardEntitiesAmount(screenType: any) {
	if (screenType === 'collateralOptimization') {
		const data = {}
		return getCollateralOptimizationEntities(false, data)
	}
	return function (dispatch) {
		const query = getEntitiesQuery(screenType)
		dispatch({
			type: GET_COMMON_DASHBOARD_ENTITIES_AMOUNT.triggered,
		})

		getPMSClient()
			.query({
				query,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_COMMON_DASHBOARD_ENTITIES_AMOUNT.succeeded,
					response,
				})
			})
	}
}

const executePortfolioQuery = portfolioQuery => {
	if (!portfolioQuery) return null
	return getPMSClient()
		.query({
			query: portfolioQuery,
			fetchPolicy: 'network-only',
		})
		.then(response => {
			return response.data
		})
		.catch(error => {
			return null
		})
}

export const getDashboardPortfolioAnalytics = portfolioQueryType => {
	return function (dispatch) {
		dispatch({
			type: GET_DASHBOARD_PORTFOLIO_ANALYTICS.triggered,
		})

		const firstPortfolioQuery =
			Array.isArray(portfolioQueryType) &&
			portfolioQueryType.length > 0 &&
			dashboardPortfolioAnalyticsQuery[portfolioQueryType[0]]
		const secondPortfolioQuery =
			Array.isArray(portfolioQueryType) &&
			portfolioQueryType.length === 2 &&
			dashboardPortfolioAnalyticsQuery[portfolioQueryType[1]]

		Promise.all([executePortfolioQuery(firstPortfolioQuery), executePortfolioQuery(secondPortfolioQuery)]).then(
			combinedResponse =>
				dispatch({
					type: GET_DASHBOARD_PORTFOLIO_ANALYTICS.succeeded,
					response: [{[portfolioQueryType[0]]: combinedResponse[0]}, {[portfolioQueryType[1]]: combinedResponse[1]}],
				})
		)
	}
}

export const resetDashboardData = () => {
	return {
		type: RESET_DASHBOARD_DATA,
	}
}

export const setTradeByData = (queryDetail: any[]) => {
	const firstQuery = queryDetail[0] && tradingQueueSummaryQuery(queryDetail[0].cptyName, queryDetail[0].cptyType)
	const secondQuery = queryDetail[1] && tradingQueueSummaryQuery(queryDetail[1].cptyName, queryDetail[1].cptyType)
	return function (dispatch) {
		dispatch({
			type: SET_TRADE_BY.triggered,
		})

		Promise.all([executePortfolioQuery(firstQuery), executePortfolioQuery(secondQuery)]).then(combinedResponse =>
			dispatch({
				type: SET_TRADE_BY.succeeded,
				response: {
					[queryDetail[0].tradeBy]: combinedResponse[0],
					[queryDetail[1].tradeBy]: combinedResponse[1],
				},
			})
		)
	}
}

export const getNotifications = () => (dispatch: any) => {
	return dispatch(get(GET_NOTIFICATIONS, `${getNotificationBaseUrl()}/v1/events`, {}, {timezone: getTimeZone()}))
}

export const getAllTrades = () => {
	const query = allTradesQuery
	return function (dispatch) {
		dispatch({type: GET_ALL_TRADES_FOR_DASHBOARD.triggered})

		const instance = axios.default.create({
			baseURL: `${getPMSGraphBaseUrl()}`,
			timeout: 1000000,
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${getAuthToken()}`,
			},
		})

		instance.post('', query).then(data => {
			dispatch({
				type: GET_ALL_TRADES_FOR_DASHBOARD.succeeded,
				response: data.data,
			})
		})
	}
}

export const HEATMAP_DASHBOARD_DATA = createFetchActions('HEATMAP_DASHBOARD_DATA')
export const fetchHeatmapAbcData = (data: any) => (dispatch: any) => {
	return dispatch(
		get(HEATMAP_DASHBOARD_DATA, `${getPMSBaseUrl()}/v1/dashboard/abc?key1=${data.key1}&key2=${data.key2}`)
	)
}

export const fetchHeatmapBoxData = (data: any) => (dispatch: any) => {
	return dispatch(
		get(HEATMAP_DASHBOARD_DATA, `${getPMSBaseUrl()}/v1/dashboard/box?key1=${data.key1}&key2=${data.key2}`)
	)
}

export const fetchHeatmapSBLData = (data: any) => (dispatch: any) => {
	return dispatch(
		get(HEATMAP_DASHBOARD_DATA, `${getPMSBaseUrl()}/v1/sbl-dashboard?key1=${data.key1}&key2=${data.key2}`)
	)
}

export const fetchHeatmapDrilldownAbcData = (data: any) => (dispatch: any) => {
	const {key1, value1, key2, type, currency} = data
	return dispatch(
		get(
			HEATMAP_DASHBOARD_DATA,
			`${getPMSBaseUrl()}/v1/dashboard/abc?key1=${key1}&value1=${toggleBlankValue(
				value1
			)}&key2=${key2}&level2=true&type=${type}&currency=${currency}`
		)
	)
}
export const fetchHeatmapDrilldownBoxData = (data: any) => (dispatch: any) => {
	const {key1, value1, key2, type, currency} = data
	return dispatch(
		get(
			HEATMAP_DASHBOARD_DATA,
			`${getPMSBaseUrl()}/v1/dashboard/box?key1=${key1}&value1=${toggleBlankValue(
				value1
			)}&key2=${key2}&level2=true&type=${type}&currency=${currency}`
		)
	)
}

export const fetchHeatmapDrilldownSBLData = (data: any) => (dispatch: any) => {
	const {value1, type, currency, key1, key2} = data
	return dispatch(
		get(
			HEATMAP_DASHBOARD_DATA,
			`${getPMSBaseUrl()}/v1/sbl-dashboard?key1=${key1}&value1=${value1}&key2=${key2}&level2=true&type=${type}&currency=${currency}`
		)
	)
}
export const HEATMAP_POSITIONS_DATA = createFetchActions('HEATMAP_POSITIONS_DATA')
export const HEATMAP_MARGIN_DATA = createFetchActions('HEATMAP_MARGIN_DATA')
export const HEATMAP_DRILLDOWN_POSITIONS_DATA = createFetchActions('HEATMAP_DRILLDOWN_POSITIONS_DATA')
export const HEATMAP_DRILLDOWN_MARGIN_DATA = createFetchActions('HEATMAP_DRILLDOWN_MARGIN_DATA')
export const fetchHeatmapPbPositionsData = (data: any) => (dispatch: any) => {
	return dispatch(
		get(
			HEATMAP_POSITIONS_DATA,
			`${getPBMSBaseUrl()}/v1/dashboard?key1=${data.key1}&key2=${data.key2}&view=${'SETTLE_DATE'}&type=`
		)
	)
}

export const fetchHeatmapPbMarginData = (data: any) => (dispatch: any) => {
	return dispatch(
		get(
			HEATMAP_MARGIN_DATA,
			`${getPBMSBaseUrl()}/v1/dashboard?key1=${data.key1}&key2=${data.key2}&view=${'MARGIN'}&type=`
		)
	)
}

export const fetchHeatmapDrilldownPbPositionsData = (data: any) => (dispatch: any) => {
	const {key1, value1, key2, type, currency} = data
	return dispatch(
		get(
			HEATMAP_DRILLDOWN_POSITIONS_DATA,
			`${getPBMSBaseUrl()}/v1/dashboard?key1=${key1}&value1=${toggleBlankValue(
				value1
			)}&key2=${key2}&level2=true&type=${type}&currency=${currency}&view=${'SETTLE_DATE'}`
		)
	)
}

export const fetchHeatmapDrilldownPbMarginData = (data: any) => (dispatch: any) => {
	const {key1, value1, key2, currency} = data
	return dispatch(
		get(
			HEATMAP_DRILLDOWN_MARGIN_DATA,
			`${getPBMSBaseUrl()}/v1/dashboard?key1=${key1}&value1=${toggleBlankValue(
				value1
			)}&key2=${key2}&level2=true&type=${null}&currency=${currency}&view=${'MARGIN'}`
		)
	)
}

export const fetchHeatmapMatureData = (data: any, screen: any) => (dispatch: any) => {
	return dispatch(
		get(
			HEATMAP_MATURE_DATA,
			`${getPMSBaseUrl()}/v1/dashboard/mature?key1=${data.key1}&key2=${data.key2}&day=${data.day}&screen=${screen}`
		)
	)
}

export const fetchHeatmapDrilldownMatureData = (data: any, screen: any) => (dispatch: any) => {
	const {key1, value1, key2, type, currency, day} = data
	return dispatch(
		get(
			HEATMAP_MATURE_DATA,
			`${getPMSBaseUrl()}/v1/dashboard/mature?key1=${key1}&value1=${toggleBlankValue(
				value1
			)}&key2=${key2}&level2=true&type=${type}&currency=${currency}&day=${day}&screen=${screen}`
		)
	)
}

export function getCollateralOptimizationSummary(recompute = false) {
	return function (dispatch) {
		dispatch(
			put(
				FETCH_COLLATERAL_OPTIMIZATION_SUMMARY_DATA,
				`${getPMSBaseUrl()}/v1/decisionMaking`,
				{
					params: {
						objective: [],
						strategy: 'Collateral Optimization',
					},
					requestType: 'summary',
					recompute,
				},

				null
			)
		)
	}
}
