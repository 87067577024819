import numeral from 'numeral'

import '../locales/de_DE'
import '../locales/en_CA'
import '../locales/en_GB'
import '../locales/en_US'
import '../locales/fr_FR'
import '../locales/it_IT'
import '../locales/ja_JA'

// migrated
export function formatWithSeparator(value) {
	return numeral(value).format('0,0')
}

// migrated
export function formatWithTwoDecimals(value) {
	return Number.isFinite(Number(value)) ? numeral(value).format('0.00') : 0
}
export function formatWithThreeDecimals(value) {
	return Number.isFinite(Number(value)) ? numeral(value).format('0.000') : 0
}

// migrated
export function formatWithFourDecimals(value) {
	return Number.isFinite(Number(value)) ? numeral(value).format('0.0000') : 0
}

// migrated
export function formatWithSeparatorAndDecimals(value) {
	return numeral(value).format('0,0.00')
}

export function formatWithSeparatorAndSixDecimals(value) {
	return numeral(value).format('0,0.000000')
}

export function formatWithSeparatorAndFiveDecimals(value) {
	return numeral(value).format('0,0.00000')
}

export function formatWithSixDecimals(value) {
	return Number.isFinite(Number(value)) ? numeral(value).format('0.000000') : 0
}

// move to feature util
export const csvJSON = csv => {
	const lines = csv.split('\n')
	let result = []
	const headers = lines[0].split(',')
	for (let i = 1; i < lines.length - 1; i++) {
		const obj = {}
		const currentline = lines[i].split(',')
		for (var j = 0; j < headers.length; j++) {
			obj[headers[j].trim()] = currentline[j].replace(/[\r\t]+/g, '')
		}
		result.push(obj)
	}
	return result
}

// unnecessary - remove it
export function joinArrayElements(data) {
	return data.join(', ')
}

// to be checked
export function abbreviationsTwoDecimal(value) {
	return String(numeral(value).format('0.00 a')).toUpperCase()
}

// to be checked
export function formatWithAbbreviations(value) {
	if (value >= 1000000000 && value <= 10000000000) {
		return String(numeral(value).format('0.00 a')).toUpperCase()
	} else if (value > 10000000000) {
		return String(numeral(value).format('0.000 a')).toUpperCase()
	} else {
		return String(numeral(value).format('0.0 a')).toUpperCase()
	}
}

export function abbreviationsNoDecimal(labelValue) {
	// Nine Zeroes for Billions
	return Math.abs(Number(labelValue)) >= 1.0e9
		? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(0) + ' B'
		: // Six Zeroes for Millions
		Math.abs(Number(labelValue)) >= 1.0e6
		? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(0) + ' M'
		: // Three Zeroes for Thousands
		Math.abs(Number(labelValue)) >= 1.0e3
		? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(0) + ' K'
		: Math.abs(Number(labelValue))
}

export function replaceUnderScores(string) {
	let words = (string && string.split('_')) || ''
	for (let i = 0; i < words.length; i++) {
		words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
	}
	return words && words.join(' ')
}
