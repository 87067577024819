import {commaNoDecimal, commaTwoDecimal, encryptURL} from '../../common/AgGrid/AgGridHelper'
import {paths} from '../../routes/routesConfig'

export const pivotTableHeadings = [
	'id',
	'Asset Type',
	'Asset Subtype',
	'Balance Sheet',
	'Duration',
	'Quantity',
	'Notional',
	'Source Notional',
	'Use Notional',
	'Source Category',
	'Use Category',
	'Source Counterparty',
	'Use Counterparty',
	'Counterparty',
	'Collateral Type',
	'Source Tenor',
	'Use Tenor',
	'HQLA',
	'Cusip',
	'Ticker',
	'Isin',
	'Security Id',
	'isRestricted',
	'Original Trade Type',
	'Legal Entity',
	'Ric',
	'BbId',
	'Security Currency',
	'Price Amount',
	'Sector',
	'Index',
	'Moody Rating',
	'S&P Rating',
	'Fitch Rating',

	'Source Quantity',
	'Source Balance Sheet',
	'Source Duration',
	'Source Trade Type',
	'Source Book',
	'Source Fund',
	'Source AllInRate',
	'Source Spread',
	'Source Reference Rate',
	'Source Buy Sell Indicator',
	'Source Source System',
	'Source Source System TradeId',
	'Source Haircut',
	'Source Collateral Amount',
	'Source Fx Rate',
	'Source Notional Currency',
	'Source Notional Amount',
	'Source Base Notional Currency',
	'Source Base Notional Amount',
	'Source Cash Amount',
	'Source Maturity Date',
	'Source Term Date',
	'Source Start Date',
	'Source End Date',
	'Source Trade Date',
	'Source Settlement Status',
	'Source Settlement Date',
	'Source Collateral Type',
	'Source Maturity Type',

	'Use Quantity',
	'Use Balance Sheet',
	'Use Duration',
	'Use Trade Type',
	'Use Book',
	'Use Fund',
	'Use All In Rate',
	'Use Spread',
	'Use Reference Rate',
	'Use Buy Sell Indicator',
	'Use Source System',
	'Use Source System TradeId',
	'Use Haircut',
	'Use Collateral Amount',
	'Use Fx Rate',
	'Use Notional Currency',
	'Use Notional Amount',
	'Use Base Notional Currency',
	'Use Base Notional Amount',
	'Use Cash Amount',
	'Use Maturity Date',
	'Use Term Date',
	'Use Start Date',
	'Use End Date',
	'Use Trade Date',
	'Use Settlement Status',
	'Use Settlement Date',
	'Use Collateral Type',
	'Use Maturity Type',
	'P&L',
]

export const pivotHiddenDimensions = [
	'id',
	'Balance Sheet',
	'Duration',
	'Notional',
	'Quantity',
	'Security',
	'Cusip',
	'Ticker',
	'isRestricted',
	// 'Security Id',
	'Isin',
	'Original Trade Type',
	'Legal Entity',
	'Ric',
	'BbId',
	// 'Security Currency',
	'Price Amount',
	'Use Term Date',
	'Source Term Date',
	'Collateral Type',
	'Counterparty',
	'Source AllInRate',
	'Source Balance Sheet',
	'Source Spread ',
	'Source Buy Sell Indicator ',
	'Source Source System',
	'Source Source System TradeId',
	'Source Haircut',
	'Source Collateral Amount',
	'Source Fx Rate',
	'Source Quantity',
	'Source Notional',
	'Source Notional Currency',
	'Source Notional Amount',
	'Source Base Notional Currency',
	'Source Base Notional Amount',
	'Source Cash Amount',
	'Source Spread',
	'Source Start Date',
	'Source End Date',
	'Source Trade Date',
	'Source Settlement Date',
	'Use Quantity',
	'Use Notional',
	'Use All In Rate',
	'Use Balance Sheet',
	'Use Spread ',
	'Use Buy Sell Indicator',
	'Use Source System',
	'Use Source System TradeId',
	'Use Haircut',
	'Use Collateral Amount',
	'Use Fx Rate',
	'Use Notional Currency',
	'Use Notional Amount',
	'Use Base Notional Currency',
	'Use Base Notional Amount',
	'Use Cash Amount',
	'Use Spread',
	'Use Start Date',
	'Use End Date',
	'Use Trade Date',
	'Use Settlement Date',
	'P&L',
]

export const pivotHiddenMetrics = [
	'id',
	'Asset Type',
	'Asset Subtype',
	'Security',
	'Source Category',
	'Use Category',
	'Source Counterparty',
	'Use Counterparty',
	'Counterparty',
	'Collateral Type',
	'Source Tenor',
	'Use Tenor',
	'HQLA',
	'Cusip',
	'Ticker',
	'isRestricted',
	'Security Id',
	'Original Trade Type',
	'Legal Entity',
	'Ric',
	'BbId',
	'Security Currency',
	'Price Amount',
	'Sector',
	'Index',
	'Moody Rating',
	'S&P Rating',
	'Fitch Rating',

	'Source Trade Type',
	'Source Book',
	'Source Fund',
	'Source AllInRate',
	'Source Spread ',
	'Source Reference Rate',
	'Source Buy Sell Indicator ',
	'Source Source System',
	'Source Source System TradeId',
	'Source Haircut',
	'Source Collateral Amount',
	'Source Fx Rate',
	'Source Notional Currency',
	'Source Notional Amount',
	'Source Base Notional Currency',
	'Source Base Notional Amount',
	'Source Cash Amount',
	'Source Maturity Date',
	'Source Term Date ',
	'Source Start Date',
	'Source End Date',
	'Source Trade Date',
	'Source Settlement Status',
	'Source Settlement Date',

	'Use Trade Type',
	'Use Book',
	'Use Fund',
	'Use All In Rate',
	'Use Spread ',
	'Use Reference Rate',
	'Use Buy Sell Indicator',
	'Use Source System',
	'Use Source System TradeId',
	'Use Haircut',
	'Use Collateral Amount',
	'Use Fx Rate',
	'Use Notional Currency',
	'Use Notional Amount',
	'Use Base Notional Currency',
	'Use Base Notional Amount',
	'Use Cash Amount',
	'Use Maturity Date',
	'Use Term Date ',
	'Use Start Date',
	'Use End Date',
	'Use Trade Date',
	'Use Settlement Status',
	'Use Settlement Date',
]

export const pivotMetrics = {
	notional: 'Notional',
	quantity: 'Quantity',
	pnL: 'P&L',
	ron: 'RON',
	durationGap: 'Duration Gap',
	balanceSheetContribution: 'Balance Sheet',
}

export const pivotViews = {
	notionalSource: 'Notional All Sources',
	notionalUse: 'Notional All Use',
	margin: 'Margin',
}

export const pivotAggregators = {
	[pivotMetrics.notional]: 'Integer Sum',
	[pivotViews.notionalUse]: 'Integer Sum',
	[pivotViews.notionalSource]: 'Integer Sum',
	[pivotViews.margin]: 'Integer Sum',
	[pivotMetrics.quantity]: 'Integer Sum',
	[pivotMetrics.pnL]: 'Integer Sum',
	[pivotMetrics.ron]: pivotMetrics.ron,
	[pivotMetrics.durationGap]: pivotMetrics.durationGap,
	[pivotMetrics.balanceSheetContribution]: 'Integer Sum',
}

export const metricValue = {
	[pivotMetrics.notional]: 'Notional',
	[pivotViews.notionalUse]: 'Use Notional',
	[pivotViews.notionalSource]: 'Source Notional',
	[pivotViews.margin]: 'Notional',
	[pivotMetrics.quantity]: 'Quantity',
	[pivotMetrics.pnL]: 'P&L',
	[pivotMetrics.ron]: 'RON',
	[pivotMetrics.durationGap]: 'Duration Gap',
	[pivotMetrics.balanceSheetContribution]: 'Balance Sheet',
}

export const columnDefinition = [
	{
		headerName: '',
		children: [
			{headerName: 'ABCM Source', field: 'Source Category'},
			{headerName: 'ABCM Use', field: 'Use Category'},
			{headerName: 'Allocated Quantity', field: 'Quantity', ...commaNoDecimal},
			{
				headerName: 'Security Id',
				field: 'Security Id',
				cellRenderer: params => {
					const data = {
						isEncrpted: true,
						selectedGroupOne: 'securityId',
						selectedGroupOneItem: params.value,
					}
					return params && params.value
						? `<span onclick='return window.open("${paths.abcmResult}?securityId=${
								params.value
						  }")'><i class="fa fa-th text-link"></i></span> &nbsp;
              <span onclick='return window.open("${paths.abcPortfolioTest}?${encryptURL(data)}")' class='text-link'>${
								params.value
						  }</span>`
						: null
				},
			},
			{headerName: 'Restricted', field: 'isRestricted'},
			{headerName: 'Cusip', field: 'Cusip'},
			{headerName: 'ISIN', field: 'Isin'},
			{headerName: 'Ticker', field: 'Ticker'},
			{headerName: 'Original Trade Type', field: 'Original Trade Type'},
			{headerName: 'Legal Entity', field: 'Legal Entity'},
			{headerName: 'Ric', field: 'Ric'},
			{headerName: 'BBId', field: 'BbId'},
			{headerName: 'Asset Type', field: 'Asset Type'},
			{headerName: 'Asset SubType', field: 'Asset Subtype'},
			{headerName: 'Price Currency', field: 'Security Currency'},
			{headerName: 'Price Amount', field: 'Price Amount', ...commaTwoDecimal},
			{headerName: 'Sector', field: 'Sector'},
			{headerName: 'Index', field: 'Index'},
			{headerName: 'Moody Rating', field: 'Moody Rating'},
			{headerName: 'S&P Rating', field: 'S&P Rating'},
			{headerName: 'Fitch Rating', field: 'Fitch Rating'},
			{headerName: 'Notional', field: 'Notional', ...commaNoDecimal},
			{headerName: 'HQLA', field: 'HQLA'},
			{headerName: 'Duration', field: 'Duration', ...commaNoDecimal},
			{headerName: 'Balance Sheet', field: 'Balance Sheet', ...commaNoDecimal},
			{headerName: 'P&L', field: 'P&L', ...commaTwoDecimal},
		],
	},
	{
		headerName: 'Source',
		headerClass: ['bg-blue'],
		children: [
			{
				headerName: 'Quantity',
				headerClass: ['bg-blue', 'ag-right-aligned-header'],
				field: 'Source Quantity',
				cellClass: ['bg-blue', 'ag-right-aligned-cell'],
				...commaNoDecimal,
			},
			{
				headerName: 'Duration',
				headerClass: ['bg-blue', 'ag-right-aligned-header'],
				field: 'Source Duration',
				cellClass: ['bg-blue', 'ag-right-aligned-cell'],
				...commaNoDecimal,
			},
			{
				headerName: 'Balance Sheet',
				headerClass: ['bg-blue', 'ag-right-aligned-header'],
				field: 'Source Balance Sheet',
				cellClass: ['bg-blue', 'ag-right-aligned-cell'],
				...commaNoDecimal,
			},
			{headerName: 'Trade Type', headerClass: ['bg-blue'], field: 'Source Trade Type', cellClass: ['bg-blue']},
			{headerName: 'Book', headerClass: ['bg-blue'], field: 'Source Book', cellClass: ['bg-blue']},
			{headerName: 'Fund', headerClass: ['bg-blue'], field: 'Source Fund', cellClass: ['bg-blue']},
			{
				headerName: 'Buy Sell Indicator',
				headerClass: ['bg-blue'],
				field: 'Source Buy Sell Indicator',
				cellClass: ['bg-blue'],
			},
			{
				headerName: 'Counterparty',
				headerClass: ['bg-blue'],
				field: 'Source Counterparty',
				cellClass: ['bg-blue'],
			},
			{
				headerName: 'Source System',
				headerClass: ['bg-blue'],
				field: 'Source Source System',
				cellClass: ['bg-blue'],
			},
			{
				headerName: 'Source System TradeId',
				headerClass: ['bg-blue'],
				field: 'Source Source System TradeId',
				cellClass: ['bg-blue'],
			},
			{
				headerName: 'All in Rate',
				headerClass: ['bg-blue', 'ag-right-aligned-header'],
				field: 'Source AllInRate',
				cellClass: ['bg-blue', 'ag-right-aligned-cell'],
				...commaTwoDecimal,
			},
			{
				headerName: 'Spread %',
				headerClass: ['bg-blue', 'ag-right-aligned-header'],
				field: 'Source Spread',
				cellClass: ['bg-blue', 'ag-right-aligned-cell'],
				...commaTwoDecimal,
			},
			{
				headerName: 'Reference Rate',
				headerClass: ['bg-blue'],
				field: 'Source Reference Rate',
				cellClass: ['bg-blue'],
			},
			{
				headerName: 'Haircut %',
				headerClass: ['bg-blue', 'ag-right-aligned-header'],
				field: 'Source Haircut',
				cellClass: ['bg-blue', 'ag-right-aligned-cell'],
				...commaTwoDecimal,
			},
			{headerName: 'Tenor', headerClass: ['bg-blue'], field: 'Source Tenor', cellClass: ['bg-blue']},
			{
				headerName: 'Fx rate',
				headerClass: ['bg-blue', 'ag-right-aligned-header'],
				field: 'Source Fx Rate',
				cellClass: ['bg-blue', 'ag-right-aligned-cell'],
			},
			{
				headerName: 'Base Notional Amount',
				headerClass: ['bg-blue', 'ag-right-aligned-header'],
				field: 'Source Base Notional Amount',
				cellClass: ['bg-blue', 'ag-right-aligned-cell'],
				...commaNoDecimal,
			},
			{
				headerName: 'Notional Currency',
				headerClass: ['bg-blue'],
				field: 'Source Base Notional Currency',
				cellClass: ['bg-blue'],
			},
			{
				headerName: 'Notional Amount',
				headerClass: ['bg-blue', 'ag-right-aligned-header'],
				field: 'Source Notional Amount',
				cellClass: ['bg-blue', 'ag-right-aligned-cell'],
				...commaNoDecimal,
			},
			{
				headerName: 'Collateral Amount',
				headerClass: ['bg-blue', 'ag-right-aligned-header'],
				field: 'Source Collateral Amount',
				cellClass: ['bg-blue', 'ag-right-aligned-cell'],
				...commaNoDecimal,
			},
			{
				headerName: 'Maturity Date',
				headerClass: ['bg-blue'],
				field: 'Source Maturity Date',
				cellClass: ['bg-blue'],
			},
			{headerName: 'Term Date', headerClass: ['bg-blue'], field: 'Source Term Date', cellClass: ['bg-blue']},
			{headerName: 'Start Date', headerClass: ['bg-blue'], field: 'Source Start Date', cellClass: ['bg-blue']},
			{headerName: 'End Date', headerClass: ['bg-blue'], field: 'Source End Date', cellClass: ['bg-blue']},
			{headerName: 'Trade Date', headerClass: ['bg-blue'], field: 'Source Trade Date', cellClass: ['bg-blue']},
			{
				headerName: 'Settlement Status',
				headerClass: ['bg-blue'],
				field: 'Source Settlement Status',
				cellClass: ['bg-blue'],
			},
			{
				headerName: 'Settlement Date',
				headerClass: ['bg-blue'],
				field: 'Source Settlement Date',
				cellClass: ['bg-blue'],
			},
			{
				headerName: 'Collateral Type',
				headerClass: ['bg-blue'],
				field: 'Source Collateral Type',
				cellClass: ['bg-blue'],
			},
			{
				headerName: 'Maturity Type',
				headerClass: ['bg-blue'],
				field: 'Source Maturity Type',
				cellClass: ['bg-blue'],
			},
		],
	},
	{
		headerName: 'Use',
		headerClass: ['bg-yellow'],
		children: [
			{
				headerName: 'Quantity',
				headerClass: ['bg-yellow', 'ag-right-aligned-header'],
				field: 'Use Quantity',
				cellClass: ['bg-yellow', 'ag-right-aligned-cell'],
				...commaNoDecimal,
			},
			{
				headerName: 'Duration',
				headerClass: ['bg-yellow', 'ag-right-aligned-header'],
				field: 'Use Duration',
				cellClass: ['bg-yellow', 'ag-right-aligned-cell'],
				...commaNoDecimal,
			},
			{
				headerName: 'Balance Sheet',
				headerClass: ['bg-yellow', 'ag-right-aligned-header'],
				field: 'Use Balance Sheet',
				cellClass: ['bg-yellow', 'ag-right-aligned-cell'],
				...commaNoDecimal,
			},
			{headerName: 'Trade Type', headerClass: ['bg-yellow'], field: 'Use Trade Type', cellClass: ['bg-yellow']},
			{headerName: 'Book', headerClass: ['bg-yellow'], field: 'Use Book', cellClass: ['bg-yellow']},
			{headerName: 'Fund', headerClass: ['bg-yellow'], field: 'Use Fund', cellClass: ['bg-yellow']},
			{
				headerName: 'Buy Sell Indicator',
				headerClass: ['bg-yellow'],
				field: 'Use Buy Sell Indicator',
				cellClass: ['bg-yellow'],
			},
			{
				headerName: 'Counterparty',
				headerClass: ['bg-yellow'],
				field: 'Use Counterparty',
				cellClass: ['bg-yellow'],
			},
			{headerName: 'Use System', headerClass: ['bg-yellow'], field: 'Use Use System', cellClass: ['bg-yellow']},
			{
				headerName: 'Use System TradeId',
				headerClass: ['bg-yellow'],
				field: 'Use Use System TradeId',
				cellClass: ['bg-yellow'],
			},
			{
				headerName: 'All in Rate',
				headerClass: ['bg-yellow', 'ag-right-aligned-header'],
				field: 'Use All In Rate',
				cellClass: ['bg-yellow', 'ag-right-aligned-cell'],
				...commaTwoDecimal,
			},
			{
				headerName: 'Spread %',
				headerClass: ['bg-yellow', 'ag-right-aligned-header'],
				field: 'Use Spread',
				cellClass: ['bg-yellow', 'ag-right-aligned-cell'],
				...commaTwoDecimal,
			},
			{
				headerName: 'Reference Rate',
				headerClass: ['bg-yellow'],
				field: 'Use Reference Rate',
				cellClass: ['bg-yellow'],
			},
			{
				headerName: 'Haircut %',
				headerClass: ['bg-yellow', 'ag-right-aligned-header'],
				field: 'Use Haircut',
				cellClass: ['bg-yellow', 'ag-right-aligned-cell'],
				...commaTwoDecimal,
			},
			{headerName: 'Tenor', headerClass: ['bg-yellow'], field: 'Use Tenor', cellClass: ['bg-yellow']},
			{
				headerName: 'Fx rate',
				headerClass: ['bg-yellow', 'ag-right-aligned-header'],
				field: 'Use Fx Rate',
				cellClass: ['bg-yellow', 'ag-right-aligned-cell'],
			},
			{
				headerName: 'Base Notional Amount',
				headerClass: ['bg-yellow', 'ag-right-aligned-header'],
				field: 'Use Base Notional Amount',
				cellClass: ['bg-yellow', 'ag-right-aligned-cell'],
				...commaNoDecimal,
			},
			{
				headerName: 'Notional Currency',
				headerClass: ['bg-yellow'],
				field: 'Use Base Notional Currency',
				cellClass: ['bg-yellow'],
			},
			{
				headerName: 'Notional Amount',
				headerClass: ['bg-yellow', 'ag-right-aligned-header'],
				field: 'Use Notional Amount',
				cellClass: ['bg-yellow', 'ag-right-aligned-cell'],
				...commaNoDecimal,
			},
			{
				headerName: 'Collateral Amount',
				headerClass: ['bg-yellow', 'ag-right-aligned-header'],
				field: 'Use Collateral Amount',
				cellClass: ['bg-yellow', 'ag-right-aligned-cell'],
				...commaNoDecimal,
			},
			{
				headerName: 'Maturity Date',
				headerClass: ['bg-yellow'],
				field: 'Use Maturity Date',
				cellClass: ['bg-yellow'],
			},
			{headerName: 'Term Date', headerClass: ['bg-yellow'], field: 'Use Term Date', cellClass: ['bg-yellow']},
			{headerName: 'Start Date', headerClass: ['bg-yellow'], field: 'Use Start Date', cellClass: ['bg-yellow']},
			{headerName: 'End Date', headerClass: ['bg-yellow'], field: 'Use End Date', cellClass: ['bg-yellow']},
			{headerName: 'Trade Date', headerClass: ['bg-yellow'], field: 'Use Trade Date', cellClass: ['bg-yellow']},
			{
				headerName: 'Settlement Status',
				headerClass: ['bg-yellow'],
				field: 'Use Settlement Status',
				cellClass: ['bg-yellow'],
			},
			{
				headerName: 'Settlement Date',
				headerClass: ['bg-yellow'],
				field: 'Use Settlement Date',
				cellClass: ['bg-yellow'],
			},
			{
				headerName: 'Collateral Type',
				headerClass: ['bg-yellow'],
				field: 'Use Collateral Type',
				cellClass: ['bg-yellow'],
			},
			{
				headerName: 'Maturity Type',
				headerClass: ['bg-yellow'],
				field: 'Use Maturity Type',
				cellClass: ['bg-yellow'],
			},
		],
	},
]

export const columnDefs = {
	sourceTradeType: 'Source Trade Type',
}

export const tableType = {
	ABCSource: 'ABCSource',
	ABCUser: 'ABCUser',
	SourceUseCategory: 'SourceUseCategory',
}
