import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'
import {appReducer, AppReducer} from '../app/appReducer'
import {dataVisualization, dataVisualizationReducer} from '../common/DataVisualization/DataVisualizationReducer'
import {
	CollateralOptimizationNewDashboard,
	CollateralOptimizationNewDashboardReducer,
} from '../features/commonDashboard/collateralOptDashboard/collateralOptLayoutReducer'
import {customiseMenuReducer, CustomiseMenu} from '../features/customiseMenu/customiseMenuReducer'
import {portfolioStatusReducer, PortfolioStatus} from '../features/portfolioStatus/portfolioStatusReducer'
import {portfolioManagerReducer, PortfolioManager} from '../pages/portfolioManager/portfolioMangerReducer'
import {loginReducer, Login} from '../pages/login/loginReducer'
import {errorReportReducer, ErrorReport} from '../pages/errorReport/errorReportReducer'
import {successReportReducer, SuccessReport} from '../pages/successReport/successReportReducer'
import {dashboardReducer, Dashboard} from '../pages/dashboard/dashboardReducers'
import {dashboardReducer2, Dashboard2} from '../features/commonDashboard/dashboardReducers'
import {abcmresultReducer, AbcmResult} from '../pages/abcmResult/abcmResultReducer'
import {Inventory, inventoryReducer} from '../pages/inventory/inventoryReducer'
import {tradeDetailsReducer, TradeDetails} from '../features/tradeDetails/tradeDetailsReducer'
import {
	CollateralBasket,
	collateralBasketReducer,
} from '../pages/collateralBasketList/collateralBasket/collateralBasketReducer'
import {DMNReducer, DMNviewer} from '../pages/DmnViewer/DmnViewerReducers'
import {OrgInfoData, orgInfoReducer} from '../pages/accountSettingsRevamp/myOrgInfo/myOrgInfoReducer'
import {
	MyLegalEntitiesData,
	myLegalEntitiesReducer,
} from '../pages/accountSettingsRevamp/myLegalEntities/myLegalEntitiesReducer'
import {MyClientsData, myClientsReducer} from '../pages/accountSettingsRevamp/myClients/myClientsReducer'
import {MyFundsData, myFundsReducer} from '../pages/accountSettingsRevamp/myFunds/myFundsReducer'
import {
	SubBusinessUnitData,
	subBusinessUnitReducer,
} from '../pages/accountSettingsRevamp/subBusinessUnit/subBusinessUnitReducer'
import {PortfolioData, portfolioReducer} from '../pages/accountSettingsRevamp/portfolios/portfoliosReducer'
import {UsersData, usersReducer} from '../pages/accountSettingsRevamp/usersList/usersListReducer'
import {DeposData, deposReducer} from '../pages/accountSettingsRevamp/depos/deposReducer'
import {OrderEntry, orderEntryReducer} from '../pages/orderEntry/orderEntryReducer'
import {OrderEntryModal, OrderEntryModalReducer} from '../pages/PeerToPeer/OrderEntryModal/OrderEntryModalReducer'
import {CBNegotiation, cbNegotiationReducer} from '../pages/cbNegotiation/cbNegotiationReducer'
import {
	collateralBasketListReducer,
	CollateralBasketList,
} from '../pages/collateralBasketList/collateralBasketListReducer'
import {
	CollateralBasketData,
	collateralBasketItemReducer,
} from '../pages/collateralBasketList/collateralBasketItems/collateralBasketItemReducer'
import {abcPortfolioReducer, AbcPortfolio} from '../pages/abcPortfolio/abcPortfolioReducer'
import {abcmRankingReducer, AbcmRanking} from '../pages/abcmResult/abcmRank/abcmRankReducer'
import {errorReducer, ErrorHandler} from '../pages/errorHandler/errorHandlerReducer'
import {needsAvailabilitiesReducer, NeedsAvailabilities} from '../pages/needsAvailabilities/needsAvailabilitiesReducer'
// import {LocateReducer, Locate} from '../pages/Locate/LocateReducer'
import {AlpAuthorizerReducer, AlpAuthorizer} from '../pages/Locate/LocateAuthorizer/LocateAuthorizerReducer'
import {AlpSttAuthorizerReducer, AlpSttAuthorizer} from '../pages/alp/AlpSttAuthorizer/AlpSttAuthorizerReducer'
import {AlpNewRequestReducer, AlpNewRequest} from '../pages/alp/AlpNewRequestModal/AlpNewRequestModalReducer'
import {ALPNegotiationModalReducer, AlpNegotiation} from '../pages/alp/NegotiationModal/NegotiationModalReducer'
import {AlpSttRequesterReducer, AlpSttRequester} from '../pages/alp/AlpSttRequester/AlpSttRequesterReducer'
import {
	AlpAuctionsInternalMainReducer,
	AlpAuctionsInternalMain,
} from '../pages/alp/Auctions/Internal/InternalMainReducer'
import {
	AlpAuctionsBorrowerMainReducer,
	AlpAuctionsBorrowerMain,
} from '../pages/alp/Auctions/Borrower/BorrowerMainReducer'
// import {LocateClientReducer, LocateClient} from '../pages/LocateClient/LocateClientReducer'
import {AlpRequesterReducer, AlpRequester} from '../pages/Locate/LocateRequester/LocateRequesterReducer'
import {
	AlpInventoryMgmtReducer,
	AlpInventoryManager,
} from '../pages/Locate/LocateInventoryManager/LocateInventoryMgmtReducer'
import {
	AlpSttInventoryMgmtReducer,
	AlpSttInventoryManager,
} from '../pages/alp/AlpSttInventoryManager/AlpSttInventoryMgmtReducer'
import {CollateralScheduleReducer, CollateralSchedule} from '../pages/CollateralSchedule/CollateralScheduleReducer'
import {
	responseActivityLogReducer,
	ResponseActivityLog,
} from '../pages/cbNegotiation/responseActivityLog/responseActivityLogReducer'
import {SettlementData, settlementDataReducer} from '../pages/settlementLadder/settlementLadderReducer'
import {
	SettlementDetailsData,
	settlementDetailsDataReducer,
} from '../pages/settlementLadder/settlementLadderDetails/settlementLadderDetailsReducer'
import {toastNotificationReducer, ToastNotification} from '../features/toastNotification/toastNotificationReducers'
import {
	fundCashFlowTitleBarReducer,
	FundCashFlowTitleBar,
} from '../features/fundCashFlowTitleBar/fundCashFlowTitleBarReducer'
import {breakEvenPricerReducer, BreakEvenPricer} from '../pages/repo-breakeven-pricer/breakEvenPricerReducer'
import {
	tenantSummaryReducer,
	TenantSummaryData,
} from '../pages/accountSettingsRevamp/accountSettingsHeader/accountSettingsHeaderReducer'
import {
	inefficienciesPopoverReducer,
	InefficienciesPopover,
} from '../features/inefficienciesPopover/inefficienciesPopoverReducer'
import {settlementCalendarReducer, SettlementCalendar} from '../pages/settlementCalendar/settlementCalendarReducer'
import {
	inventoryOptimizationReducer,
	InventoryOptimization,
} from '../pages/inventoryOptimization/inventoryOptimizationReducer'
import {DecisionMaking, decisionMakingReducer} from '../pages/decisionMaking/decisionMakingReducer'
import {
	DecisionMakingDashboard,
	DecisionMakingDashboardReducer,
} from '../pages/DecisionMakingDashboard/DecisionMakingDashboardReducer'
import {ClientScoring, clientScoringReducer} from '../pages/ClientScoring/ClientScoringReducer'
import {tradingQueueReducer, TradingQueue} from '../pages/tradingQueue/tradingQueueReducer'
import {repoReducer, Repo} from '../pages/repo/repoReducers'
import {graphWidgetsReducer, GraphWidgets} from '../pages/graph-widgets/graphWidgetsReducers'
import {reportsReducer, Reports} from '../pages/reports/reportReducers'
import {reportsSyncfusionReducer, ReportsSyncfusion} from '../pages/reports-syncfusion/reportsSyncfusionReducers'
import {marketDataReducer, MarketData} from '../pages/marketData/marketDataReducers'
import {marketDataReducer2, MarketData2} from '../pages/marketData-test/marketDataReducers'
import {ActivityTypeReducer, ActivityType} from '../pages/common/ActivityType/ActivityTypeReducer'
import {SimmReducer, Simm} from '../pages/Simm/SimmReducer'
import {AbcmStatusReducer, AbcmStatus} from '../features/abcmStatus/abcmStatusReducer'
import {Box, boxesReducer} from '../pages/common/tradeDetails/boxesTrades/boxesReducers'
import {sblReducer, Sbl} from '../pages/sblDashboard/sblReducers'
import {
	collateralOptimizationReducer,
	CollateralOptimization,
} from '../pages/collateralOptimization/collateralOptimizationReducer'
import {
	CollateralOptimizationTradesReducer,
	CollateralOptimizationTrades,
} from '../pages/common/tradeDetails/CollateralOptimizationTrades/CollateralOptimizationTradesReducers'
import {operationsReducer, Operations} from '../pages/operations/operationsReducer'
import {maturingTradesReducer, MaturingTrades} from '../pages/common/tradeDetails/maturingTrades/maturingTradesReducer'
import {
	MaturityTradeTable,
	maturityTradeTableReducer,
} from '../pages/common/MaturityTradeTable/MaturityTradeTableReducer'
import {IndexAnalysisReducer, IndexAnalysis} from '../pages/indexAnalysis/indexAnalysisReducer'
import {IndexPricerReducer, IndexPricer} from '../pages/indexPricing/IndexPricingReducer'
import {Lcr, LCRReducer} from '../pages/LCR/LCRReducer'
import {CusipSchedule, CusipScheduleReducer} from '../pages/CusipSchedule/CusipScheduleReducer'
import {Abpm, ABPMReducer} from '../pages/ABPM/ABPMReducer'
import {CounterpartyHaircut, CounterpartyHaircutReducer} from '../pages/CounterpartyHaircut/CounterpartyHaircutReducer'
import {MarginAnalytics, MarginAnalyticsReducer} from '../pages/marginAnalytics/marginAnalyticsReducer'
import {MarginAnalysis, MarginAnalysisReducer} from '../pages/MarginAnalysis/MarginAnalysisReducer'
import {IntradayActivities, IntradayActivitiesReducer} from '../pages/intradayActivities/intradayActivitiesReducer'
import {ReRate, ReRateReducer} from '../pages/common/tradeDetails/ReRate/ReRateReducer'
import {MarginManagement, MarginManagementReducer} from '../pages/MarginManagement/MarginManagementReducer'
import {PbAbcPortfolio, pbAbcPortfolioReducer} from '../pages/primeBroker/pbAbcPortfolio/pbAbcPortfolioReducer'
import {
	PbNeedsAvailabilities,
	pbNeedsAvailabilitiesReducer,
} from '../pages/primeBroker/pbNeedsAndAvailabilities/pbNeedsAvailabilitiesReducer'
import {
	PbPortfolioManager,
	pbPortfolioManagerReducer,
} from '../pages/primeBroker/pbPortfolioManager/pbPortfolioManagerReducer'
import {fmWidgetsReducer, FmWidgets} from '../common/flexMonster/FmWidget/FmWidgetReducers'
import {TradingTicket, tradingTicketReducer} from '../pages/common/TradingTicket/TradingTicketReducer'
import {PeerToPeer, PeerToPeerReducer} from '../pages/PeerToPeer/PeerToPeerReducer'
import {WidgetDropDown, WidgetDropDownReducer} from '../pages/PeerToPeer/WidgetDropDown/WidgetDropDownReducers'
import {MyBUData, myBUReducer} from '../pages/accountSettingsRevamp/myBU/myBUReducer'
import {MyAccountsData, myAccountsReducer} from '../pages/accountSettingsRevamp/myAccounts/myAccountsReducer'
import {UserGroupData, userGroupReducer} from '../pages/accountSettingsRevamp/userGroups/userGroupReducer'
import {AllocationData, AllocationReducer} from '../pages/accountSettingsRevamp/allocations/AllocationReducer'
import {ChangePassword, changePasswordReducer} from '../pages/changePassword/changePasswordReducer'
import {dummyWidget, dummyWidgetReducer} from '../features/widget/DummyWidgetReducer'
import {GcRatesReducer} from '../pages/GcRates/GcRatesReducer'
import {GcRates} from '../pages/GcRates/GcRatesReducer'
import {NewTradeModal, newTradeModalReducer} from '../pages/TradeBlotter/NewTradeModal/NewTradeModalReducer'
import {TradeBlotter, tradeBlotterReducer} from '../pages/TradeBlotter/TradeBlotterReducer'
import {AssetValues, AssetValuesReducer} from '../pages/assetValues/AssetValuesReducer'
import {BenchmarkWeights, BenchmarkWeightsReducer} from '../pages/benchmarkWeight/BenchmarkWeightsReducer'

export interface MainState {
	app: AppReducer // Note: A to Z order
	alp: AlpAuthorizer
	alpStt: AlpSttAuthorizer
	alpClient: AlpRequester
	alpNewRequest: AlpNewRequest
	alpNegotiation: AlpNegotiation
	alpSttClient: AlpSttRequester
	alpAuctionsInternalMain: AlpAuctionsInternalMain
	alpAuctionsBorrowerMain: AlpAuctionsBorrowerMain
	alpInventoryManager: AlpInventoryManager
	alpSttInventoryManager: AlpSttInventoryManager
	abcmResult: AbcmResult
	abcPortfolio: AbcPortfolio
	abcmStatus: AbcmStatus
	activityType: ActivityType
	abcmRankingData: AbcmRanking
	abpm: Abpm
	assetValues: AssetValues
	benchmarkWeights: BenchmarkWeights
	box: Box
	breakEvenPricer: BreakEvenPricer
	changePassword: ChangePassword
	collateralBasket: CollateralBasket
	collateralBasketData: CollateralBasketData
	collateralBasketList: CollateralBasketList
	cbNegotiation: CBNegotiation
	collateralOptimization: CollateralOptimization
	collateralOptimizationTrades: CollateralOptimizationTrades
	counterpartyHaircut: CounterpartyHaircut
	cusipSchedule: CusipSchedule
	customiseMenu: CustomiseMenu
	dataVisualization: dataVisualization
	dashboard: Dashboard
	dashboard2: Dashboard2
	depos: DeposData
	collateralOptimizationDashboard: DecisionMakingDashboard
	CollateralOptimizationNewDashboard: CollateralOptimizationNewDashboard
	dmnViewer: DMNviewer
	decisionMaking: DecisionMaking
	clientScoring: ClientScoring
	dummyWidget: dummyWidget
	errorHandler: ErrorHandler
	errorReport: ErrorReport
	fundCashFlowTitleBar: FundCashFlowTitleBar
	fmWidgets: FmWidgets
	gcRates: GcRates
	graphWidgets: GraphWidgets
	inefficienciesPopover: InefficienciesPopover
	inventory: Inventory
	inventoryOptimization: InventoryOptimization
	indexAnalysis: IndexAnalysis
	indexPricer: IndexPricer
	lcr: Lcr
	intradayActivities: IntradayActivities
	collateralSchedule: CollateralSchedule
	login: Login
	marginAnalysis: MarginAnalysis
	marginAnalytics: MarginAnalytics
	marginManagement: MarginManagement
	maturingTrades: MaturingTrades
	maturityTradeTable: MaturityTradeTable
	myActivityOrdersData: any
	myAccounts: MyAccountsData
	myAllocations: AllocationData
	myClients: MyClientsData
	myFunds: MyFundsData
	subBusinessUnit: SubBusinessUnitData
	portfolios: PortfolioData
	myBU: MyBUData
	myLegalEntities: MyLegalEntitiesData
	needsAvailabilities: NeedsAvailabilities
	operations: Operations
	orderEntry: OrderEntry
	orderEntryModal: OrderEntryModal
	orgInfo: OrgInfoData
	pbAbcPortfolio: PbAbcPortfolio
	pbNeedsAvailabilities: PbNeedsAvailabilities
	pbPortfolioManager: PbPortfolioManager
	peerToPeer: PeerToPeer
	portfolioManager: PortfolioManager
	portfolioStatus: PortfolioStatus
	router: any
	responseActivityLog: ResponseActivityLog
	repo: Repo
	report: Reports
	reportsSyncfusion: ReportsSyncfusion
	reRate: ReRate
	marketData: MarketData
	marketData2: MarketData2
	newTradeModal: NewTradeModal
	sbl: Sbl
	settlementCalendar: SettlementCalendar
	settlementData: SettlementData
	settlementDetailsData: SettlementDetailsData
	simm: Simm
	successReport: SuccessReport
	tenantSummary: TenantSummaryData
	toastNotification: ToastNotification
	tradeDetails: TradeDetails
	tradingQueue: TradingQueue
	users: UsersData
	userGroup: UserGroupData
	tradeBlotter: TradeBlotter
	tradingTicket: TradingTicket
	WidgetDropDown: WidgetDropDown
}

export const createMainReducer = (history: any) =>
	combineReducers({
		app: appReducer, // Note: A to Z order
		abcmRankingData: abcmRankingReducer,
		abcmResult: abcmresultReducer,
		abcPortfolio: abcPortfolioReducer,
		abcmStatus: AbcmStatusReducer,
		activityType: ActivityTypeReducer,
		abpm: ABPMReducer,
		alp: AlpAuthorizerReducer,
		alpStt: AlpSttAuthorizerReducer,
		alpClient: AlpRequesterReducer,
		alpNewRequest: AlpNewRequestReducer,
		alpNegotiation: ALPNegotiationModalReducer,
		alpSttClient: AlpSttRequesterReducer,
		alpAuctionsInternalMain: AlpAuctionsInternalMainReducer,
		alpAuctionsBorrowerMain: AlpAuctionsBorrowerMainReducer,
		alpInventoryManager: AlpInventoryMgmtReducer,
		alpSttInventoryManager: AlpSttInventoryMgmtReducer,
		assetValues: AssetValuesReducer,
		benchmarkWeights: BenchmarkWeightsReducer,
		dataVisualization: dataVisualizationReducer,
		box: boxesReducer,
		breakEvenPricer: breakEvenPricerReducer,
		changePassword: changePasswordReducer,
		collateralBasket: collateralBasketReducer,
		collateralBasketData: collateralBasketItemReducer,
		collateralBasketList: collateralBasketListReducer,
		cbNegotiation: cbNegotiationReducer,
		collateralOptimization: collateralOptimizationReducer,
		CollateralOptimizationNewDashboard: CollateralOptimizationNewDashboardReducer,
		collateralOptimizationTrades: CollateralOptimizationTradesReducer,
		counterpartyHaircut: CounterpartyHaircutReducer,
		cusipSchedule: CusipScheduleReducer,
		customiseMenu: customiseMenuReducer,
		dashboard: dashboardReducer,
		dashboard2: dashboardReducer2,
		depos: deposReducer,
		dmnViewer: DMNReducer,
		decisionMaking: decisionMakingReducer,
		collateralOptimizationDashboard: DecisionMakingDashboardReducer,
		clientScoring: clientScoringReducer,
		dummyWidget: dummyWidgetReducer,
		errorReport: errorReportReducer,
		errorHandler: errorReducer,
		fundCashFlowTitleBar: fundCashFlowTitleBarReducer,
		fmWidgets: fmWidgetsReducer,
		gcRates: GcRatesReducer,
		graphWidgets: graphWidgetsReducer,
		inefficienciesPopover: inefficienciesPopoverReducer,
		intradayActivities: IntradayActivitiesReducer,
		inventory: inventoryReducer,
		indexAnalysis: IndexAnalysisReducer,
		indexPricer: IndexPricerReducer,
		inventoryOptimization: inventoryOptimizationReducer,
		lcr: LCRReducer,
		collateralSchedule: CollateralScheduleReducer,
		login: loginReducer,
		marginAnalysis: MarginAnalysisReducer,
		marginAnalytics: MarginAnalyticsReducer,
		marginManagement: MarginManagementReducer,
		marketData: marketDataReducer,
		marketData2: marketDataReducer2,
		maturingTrades: maturingTradesReducer,
		maturityTradeTable: maturityTradeTableReducer,
		myAccounts: myAccountsReducer,
		myAllocations: AllocationReducer,
		myBU: myBUReducer,
		myClients: myClientsReducer,
		myFunds: myFundsReducer,
		subBusinessUnit: subBusinessUnitReducer,
		portfolios: portfolioReducer,
		myLegalEntities: myLegalEntitiesReducer,
		needsAvailabilities: needsAvailabilitiesReducer,
		newTradeModal: newTradeModalReducer,
		operations: operationsReducer,
		orderEntry: orderEntryReducer,
		orderEntryModal: OrderEntryModalReducer,
		orgInfo: orgInfoReducer,
		pbAbcPortfolio: pbAbcPortfolioReducer,
		pbNeedsAvailabilities: pbNeedsAvailabilitiesReducer,
		pbPortfolioManager: pbPortfolioManagerReducer,
		peerToPeer: PeerToPeerReducer,
		portfolioManager: portfolioManagerReducer,
		portfolioStatus: portfolioStatusReducer,
		repo: repoReducer,
		report: reportsReducer,
		reportsSyncfusion: reportsSyncfusionReducer,
		responseActivityLog: responseActivityLogReducer,
		router: connectRouter(history),
		reRate: ReRateReducer,
		sbl: sblReducer,
		settlementCalendar: settlementCalendarReducer,
		settlementData: settlementDataReducer,
		settlementDetailsData: settlementDetailsDataReducer,
		simm: SimmReducer,
		successReport: successReportReducer,
		tenantSummary: tenantSummaryReducer,
		toastNotification: toastNotificationReducer,
		tradeDetails: tradeDetailsReducer,
		tradingQueue: tradingQueueReducer,
		users: usersReducer,
		userGroup: userGroupReducer,
		tradeBlotter: tradeBlotterReducer,
		tradingTicket: tradingTicketReducer,
		WidgetDropDown: WidgetDropDownReducer,
	})
