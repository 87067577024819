import * as axios from 'axios'
import {createFetchActions} from '../../services/createActions'
import {allTradesQuery} from '../dashboard/dashboardQueries'
import {getPMSGraphBaseUrl} from '../../services/apiService'
import {getAuthToken} from '../../services/authService'
export const GET_ALL_TRADES_FOR_OPERATIONS = createFetchActions('GET_ALL_TRADES_FOR_OPERATIONS')

export const getAllTrades = () => {
	const query = allTradesQuery
	return function (dispatch) {
		dispatch({type: GET_ALL_TRADES_FOR_OPERATIONS.triggered})

		const instance = axios.default.create({
			baseURL: `${getPMSGraphBaseUrl()}`,
			timeout: 1000000,
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${getAuthToken()}`,
			},
		})

		instance.post('', query).then(data => {
			dispatch({
				type: GET_ALL_TRADES_FOR_OPERATIONS.succeeded,
				response: data.data,
			})
		})
	}
}
