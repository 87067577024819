import React from 'react'
import {Config} from '../../constants/clientConfig'
import {history} from '../../store/mainStore'
import {ArrowLeft, VenturiLogoLong} from '../../styles/svg/svgIcons'

interface Props {
	title: string | any
	children?: any
	enableBackButton?: boolean | Function
	enableContentLeftBorder?: boolean
	enableHeaderExpansion?: boolean
}

interface State {}

export default class Header extends React.Component<Props, State> {
	static defaultProps: Partial<Props> = {
		children: null,
		enableBackButton: false,
		enableContentLeftBorder: false,
		enableHeaderExpansion: false,
	}

	render() {
		const {title, children, enableBackButton, enableContentLeftBorder, enableHeaderExpansion} = this.props

		return (
			<div className='page-header align-items-center'>
				<div className='d-flex flex-fill'>
					<div className='header-logo'>
						{process.env.REACT_APP_ENV === 'dev' ? (
							<div className='header-logo-color text-center f-24'>
								<VenturiLogoLong />
							</div>
						) : (
							Config.activeConfig.logo
						)}
					</div>

					<div className='d-flex align-items-center w-100'>
						<div className='d-flex align-items-center'>
							{enableBackButton === true && history && (
								<div className='ml-3 d-flex cursor-pointer' onClick={() => history.goBack()}>
									<ArrowLeft />
								</div>
							)}
							{typeof enableBackButton === 'function' && (
								<div className='ml-3 d-flex cursor-pointer' onClick={() => enableBackButton()}>
									<ArrowLeft />
								</div>
							)}
							<div
								className={`pl-4 text-light-grey header-title ${enableHeaderExpansion ? '' : 'max-w-450px'}`}
								title={title}
							>
								{title}
							</div>
						</div>
						<div className={`w-100 ${enableContentLeftBorder ? 'border-left' : 'd-flex'}`}>{children}</div>
					</div>
				</div>
			</div>
		)
	}
}
