import React from 'react'
import {paths} from '../../routes/routesConfig'
import {
	IconExeTradingQueue,
	IconIQInventoryOptimization,
	IconIQDecisionMaking,
	IconIQSIMM,
	IconExeRepo,
	IconExeSBL,
	IconExeP2P,
	IconExeAvailabilityAndNeed,
	IconPCCollateralBasket,
	IconATYAbcPortfolio,
	IconATYAbcmAnalytics,
	IconATYInventoryManagemnet,
	IconAYTMarketData,
	IconAYTPricingTool,
	IconAYTMaturityCalendar,
	IconAYTSettlementLadder,
	IconMenuDashboardOne,
	// IconDaMiReports,
	IconRepoPricier,
	IconOperations,
	IconLocateSearch,
	IconIndexTradingAndAnalytics,
	IconClientScoring,
	IconPortfolioManager,
	IconDaMiReports,
} from '../../styles/svg/svgIcons'

export const menuValues = {
	portfolioManager: {
		label: 'Portfolio Manager',
		value: 'portfolioManager',
		menuLayer: 'portfolioManager',
		path: paths.portfolioManager,
		icon: <IconPortfolioManager />,
	},
	dashboard: {
		label: 'Dashboard',
		value: 'dashboard',
		menuLayer: 'dashboard',
		path: paths.dashboardTest, // TEMP-FIX
		icon: <IconMenuDashboardOne />,
	},
	abcmAnalytics: {
		label: 'ABCM Analytics',
		value: 'abcmAnalytics',
		menuLayer: 'analytics',
		path: paths.abcmResult,
		icon: <IconATYAbcmAnalytics />,
	},
	abcPortfolio: {
		label: 'ABC Portfolio',
		value: 'abcPortfolio',
		menuLayer: 'analytics',
		path: paths.abcPortfolioTest,
		icon: <IconATYAbcPortfolio />,
	},
	alp: {
		label: 'ALP Authorizer',
		value: 'alp',
		menuLayer: 'execution',
		path: paths.alp,
		icon: <IconLocateSearch />,
	},
	alpClient: {
		label: 'ALP Requester',
		value: 'alpClient',
		menuLayer: 'execution',
		path: paths.alpClient,
		icon: <IconLocateSearch />,
	},
	alpInventoryManager: {
		label: 'ALP Inventory Manager',
		value: 'alpInventoryManager',
		menuLayer: 'execution',
		path: paths.alpInventoryManager,
		icon: <IconLocateSearch />,
	},
	inventory: {
		label: 'Inventory Manager',
		value: 'inventory',
		menuLayer: 'analytics',
		path: paths.inventory,
		icon: <IconATYInventoryManagemnet />,
	},
	settlementLadder: {
		label: 'Settlement Ladder',
		value: 'settlementLadder',
		menuLayer: 'analytics',
		path: paths.settlementLadder,
		icon: <IconAYTSettlementLadder />,
	},
	maturityCalendar: {
		label: 'Maturity Calendar',
		value: 'maturityCalendar',
		menuLayer: 'analytics',
		path: paths.settlementCalendar,
		icon: <IconAYTMaturityCalendar />,
	},
	decisionMaking: {
		label: 'Decision Making',
		value: 'decisionMaking',
		menuLayer: 'quantitativeIntelligence',
		path: paths.decisionMaking,
		icon: <IconIQDecisionMaking />,
	},
	collateralOptimizationDashboard: {
		label: 'Collateral Optimization Dashboard',
		value: 'collateralOptimizationDashboard',
		menuLayer: 'quantitativeIntelligence',
		path: paths.collateralOptimizationDashboard,
		icon: <IconIQDecisionMaking />,
	},
	simm: {
		label: 'SIMM Analytics',
		value: 'simm',
		menuLayer: 'quantitativeIntelligence',
		path: paths.simm,
		icon: <IconIQSIMM />,
	},
	clientScoring: {
		label: 'Client Scoring',
		value: 'clientScoring',
		menuLayer: 'quantitativeIntelligence',
		path: paths.clientScoring,
		icon: <IconClientScoring />,
	},
	indexAnalytics: {
		label: 'Index Trading & Analytics',
		value: 'indexAnalytics',
		menuLayer: 'portfolioAnalytics',
		path: paths.indexAnalysis,
		icon: <IconIndexTradingAndAnalytics />,
	},
	inventoryOptimization: {
		label: 'Inventory Optimization',
		value: 'inventoryOptimization',
		menuLayer: 'portfolioAnalytics',
		path: paths.inventoryOptimization,
		icon: <IconIQInventoryOptimization />,
	},
	collateralBasket: {
		label: 'Collateral Basket',
		value: 'collateralBasket',
		menuLayer: 'portfolioAnalytics',
		path: paths.collateralBasketList,
		icon: <IconPCCollateralBasket />,
	},
	tradingQueue: {
		label: 'Trading Queue',
		value: 'tradingQueue',
		menuLayer: 'execution',
		path: paths.tradingQueue,
		icon: <IconExeTradingQueue />,
	},
	p2p: {
		label: 'P2P',
		value: 'p2p',
		menuLayer: 'execution',
		// path: paths.marketPlace,
		path: paths.peerToPeer,
		icon: <IconExeP2P />,
	},
	needsAvailabilities: {
		label: 'AV & N',
		value: 'needsAvailabilities',
		menuLayer: 'execution',
		path: paths.needsAvailabilities,
		icon: <IconExeAvailabilityAndNeed />,
	},
	repo: {
		label: 'Repo',
		value: 'repo',
		menuLayer: 'execution',
		path: paths.repo,
		icon: <IconExeRepo />,
	},
	sbl: {
		label: 'SBL',
		value: 'sbl',
		menuLayer: 'execution',
		path: paths.sbl,
		icon: <IconExeSBL />,
	},
	intradayActivities: {
		label: 'Intraday Activity',
		value: 'intradayActivities',
		menuLayer: 'execution',
		path: paths.intradayActivities,
		icon: <IconDaMiReports />,
	},
	// reports: {
	// 	label: 'Reports',
	// 	value: 'reports',
	// 	menuLayer: 'dataMining',
	// 	path: paths.reports,
	// 	icon: <IconDaMiReports />,
	// },
	marketData: {
		label: 'Reports Dashboard',
		value: 'marketData',
		menuLayer: 'dataMining',
		path: paths.marketData,
		icon: <IconAYTMarketData />,
	},
	operations: {
		label: 'Operations',
		value: 'operations',
		menuLayer: 'operations',
		path: paths.operations,
		icon: <IconOperations />,
	},
	indexPricer: {
		label: 'Index Pricer',
		value: 'indexPricer',
		menuLayer: 'pricingTools',
		path: paths.indexPricer,
		icon: <IconAYTPricingTool />,
	},
	repoPricer: {
		label: 'Repo Pricer',
		value: 'repoPricer',
		menuLayer: 'pricingTools',
		path: paths.breakevenPricer,
		icon: <IconRepoPricier />,
	},
	tradeBlotter: {
		label: 'Trade blotter',
		// value: 'alpClient',
		menuLayer: 'execution',
		path: paths.alpClient,
		icon: <IconLocateSearch />,
	},
}

export const menuLayers = {
	portfolioManager: {
		isLayer: false,
		options: menuValues.portfolioManager,
	},
	dashboard: {
		isLayer: false,
		options: menuValues.dashboard,
	},
	analytics: {
		isLayer: true,
		layerTitle: 'Analytics',
		options: [
			menuValues.abcmAnalytics,
			menuValues.abcPortfolio,
			menuValues.inventory,
			menuValues.settlementLadder,
			menuValues.maturityCalendar,
		],
	},
	quantitativeIntelligence: {
		isLayer: true,
		layerTitle: 'Quantitative Intelligence',
		options: [
			menuValues.decisionMaking,
			menuValues.collateralOptimizationDashboard,
			menuValues.simm,
			menuValues.clientScoring,
		],
	},
	portfolioAnalytics: {
		isLayer: true,
		layerTitle: 'Portfolio Analytics',
		options: [menuValues.indexAnalytics, menuValues.inventoryOptimization, menuValues.collateralBasket],
	},
	execution: {
		isLayer: true,
		layerTitle: 'Execution',
		options: [
			menuValues.tradingQueue,
			menuValues.p2p,
			menuValues.needsAvailabilities,
			menuValues.alp,
			menuValues.alpClient,
			menuValues.repo,
			menuValues.sbl,
		],
	},
	dataMining: {
		isLayer: true,
		layerTitle: 'Data Mining',
		options: [menuValues.marketData],
	},
	operations: {
		isLayer: false,
		options: menuValues.operations,
	},
	pricingTools: {
		isLayer: true,
		layerTitle: 'Pricing Tools',
		options: [menuValues.indexPricer, menuValues.repoPricer],
	},
}
