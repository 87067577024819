import gql from 'graphql-tag'
import {getCounterpartyId} from '../../../services/authService'

export const getMyClientsQuery = gql`
	query {
		allClients(tenantId: "${getCounterpartyId()}") {
			tenantId
			clientId
			isActive
			fullName
			shortName
			legalEntityExternalId
			externalProviderRefId
			clientRanking
      clientRiskType
			clientType
			internalCreditRating
			country
			orgId
			entityType
			entitySubType
			relationships
			depos {
				clientDeposId
				id
				deposType
				deposValue
			}
			limits {
				clientLimitsId
				id
				limitsCategory
				limitsType
				assetType
				limitsValue
				expiry
			}
			durationLimits {
				durationLimitsId
				id
				assetType
				days
				expiry
			}
      agreementTypes
      clientRiskTypeDesc
      clientTypeDesc
      contractTypes
			properties {
				propertyId
				id
				agreementType
				propertyKey
				propertyValue
				propertyDataType
			}
		}
	}
`
