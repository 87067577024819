import {myTradeBookQuery, availabilitiesWithScheduleQuery} from './MarginManagementQuery'
import {getMPSBaseUrl, getMPSGraphBaseUrl, getPMSGraphBaseUrl} from '../../services/apiService'
import {createFetchActions} from '../../services/createActions'
import {get, graphQlWithAxios, post} from '../../services/createAPICall'

export const GET_MY_TRADE_BOOK = createFetchActions('GET_MY_TRADE_BOOK')
export const GET_MARGIN_DETAIL = createFetchActions('GET_MARGIN_DETAIL')
export const SAVE_MARGIN_DETAIL = createFetchActions('SAVE_MARGIN_DETAIL')
export const GET_AVAILABILITIES = createFetchActions('GET_AVAILABILITIES')
export const GET_AVAILABILITIES_WITH_SCHEDULE = createFetchActions('GET_AVAILABILITIES_WITH_SCHEDULE')

export const getMyTradeBook = () => {
	const query = myTradeBookQuery()
	const baseURL = `${getMPSGraphBaseUrl()}`
	return function (dispatch) {
		graphQlWithAxios(dispatch, GET_MY_TRADE_BOOK, baseURL, query, (response: any) => {
			dispatch({
				type: GET_MY_TRADE_BOOK.succeeded,
				response: response && response.data && response.data.data,
			})
		})
	}
}

export const getMarginDetail = (orderId: string) => (dispatch: any) => {
	return dispatch(get(GET_MARGIN_DETAIL, `${getMPSBaseUrl()}/v1/mps/orders/open-trades/${orderId}/details`))
}

export const getAvailabilitiesWithSchedule = (schedule, book: string = null, clientRiskType = null) => {
	const query = availabilitiesWithScheduleQuery(schedule, book, clientRiskType)
	const baseURL = `${getPMSGraphBaseUrl()}`
	return function (dispatch) {
		dispatch({type: GET_AVAILABILITIES_WITH_SCHEDULE.triggered})
		graphQlWithAxios(dispatch, GET_AVAILABILITIES_WITH_SCHEDULE, baseURL, query, (response: any) => {
			dispatch({
				type: GET_AVAILABILITIES_WITH_SCHEDULE.succeeded,
				response: response && response.data && response.data.data,
			})
		})
	}
}

export const saveMarginDetail = (orderId: string, payload: any) => (dispatch: any) => {
	return dispatch(post(SAVE_MARGIN_DETAIL, `${getMPSBaseUrl()}/v1/order-settlements/${orderId}`, payload))
}
