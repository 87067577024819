import {createFetchActions} from '../../services/createActions'
import {getPMSBaseUrl} from '../../services/apiService'
import {get, put} from '../../services/createAPICall'

export const DELETE_TRADE = createFetchActions('DELETE_TRADE')
export const GET_TRADES = createFetchActions('GET_TRADES')

export const deleteTrade = (id: any) => (dispatch: any) => {
	return dispatch(put(DELETE_TRADE, `${getPMSBaseUrl()}/v1/tradeBlotter/archive/${id}`))
}

export const getTrades = () => dispatch => {
	return dispatch(get(GET_TRADES, `${getPMSBaseUrl()}/v1/tradeBlotter`))
}
