import {
	GET_AVAILABILITIES,
	GET_AVAILABILITIES_WITH_SCHEDULE,
	GET_MARGIN_DETAIL,
	GET_MY_TRADE_BOOK,
} from './MarginManagementAction'

export interface MarginManagement {
	loading: boolean
	myTradeBook: any[]
	marginDetail: any
	availabilities: any
	availabilitiesWithSchedule: any
}

const initialState: MarginManagement = {
	loading: false,
	myTradeBook: null,
	marginDetail: {},
	availabilities: null,
	availabilitiesWithSchedule: null,
}

export function MarginManagementReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_MY_TRADE_BOOK.triggered:
			return {...state}
		case GET_MY_TRADE_BOOK.succeeded:
			const myTradeBook = action.response && action.response.marginTrades ? action.response.marginTrades : []
			return {...state, myTradeBook}
		case GET_MY_TRADE_BOOK.failed:
			return {...state, myTradeBook: []}

		case GET_MARGIN_DETAIL.triggered || GET_MARGIN_DETAIL.failed:
			return {...state, marginDetail: state.marginDetail}
		case GET_MARGIN_DETAIL.succeeded:
			const marginDetail = JSON.parse(JSON.stringify(state.marginDetail))
			marginDetail[action.response.data.orderId] = action.response.data
			return {...state, marginDetail}

		case GET_AVAILABILITIES.triggered:
			return {...state, availabilities: null}
		case GET_AVAILABILITIES.succeeded:
			return {...state, availabilities: action.response.portfolio.boxesEnrichDetails.entries}
		case GET_AVAILABILITIES.failed:
			return {...state, availabilities: state.availabilities}

		case GET_AVAILABILITIES_WITH_SCHEDULE.triggered:
			return {...state, availabilitiesWithSchedule: null}
		case GET_AVAILABILITIES_WITH_SCHEDULE.succeeded:
			return {...state, availabilitiesWithSchedule: action.response.boxesEnrichDetailsForSchedule.entries}
		case GET_AVAILABILITIES_WITH_SCHEDULE.failed:
			return {...state, availabilitiesWithSchedule: state.availabilitiesWithSchedule}
	}
	return state
}
