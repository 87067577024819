import jwt_decode from 'jwt-decode'
import Lodash from 'lodash'

import {getObjectFromStorage, getStringFromStorage} from './storageService'
import {setObjectInStorage, setStringInStorage, removeItemFromStorage} from './storageService'
import {configKey, setPermissionsInStorage, permissionKey} from './clientConfigurationService'
import {amplifyLogout} from '../utils/amplify'
import {parseQuery} from '../utils/queryParse'

export const authTokenKey = 'authTokenKey'
export const userKey = 'userKey'
export const pendingAbcmStatus = 'pendingAbcmStatus'
export const theme = 'theme'
export const marketPlaceLayout = 'marketPlaceLayout'
export const settlementLadderColorData = 'settlementLadderColorData'
export const activeClientConfig = 'activeClientConfig'

export function isAuthenticated() {
	const token = getStringFromStorage(authTokenKey)
	const decodedToken = token && jwt_decode(token)
	if (!Lodash.isEmpty(decodedToken)) {
		const expiryTime = decodedToken.exp
		const currentTime = new Date().getTime() / 1000
		const time = expiryTime > currentTime ? expiryTime - currentTime : 0
		return time * 1000 - 60000 > 0
	} else {
		const query: any = parseQuery(window.location.search)
		const tenantCode: string = query && query.tenant_code ? query.tenant_code : ''
		tenantCode && setStringInStorage('tenant', tenantCode)
		return false
	}
}

export function setAuthToken(authToken = '') {
	setStringInStorage(authTokenKey, authToken)
}

export function getAuthToken() {
	return getStringFromStorage(authTokenKey)
}

export function clearAuthToken() {
	removeItemFromStorage(authTokenKey)
	removeItemFromStorage(userKey)
	removeItemFromStorage(configKey)
	removeItemFromStorage(permissionKey)
	removeItemFromStorage(pendingAbcmStatus)
	removeItemFromStorage(marketPlaceLayout)
	removeItemFromStorage(settlementLadderColorData)
	removeItemFromStorage(activeClientConfig)
	amplifyLogout()
}

export function setUser(user = {}) {
	setObjectInStorage(userKey, user)
}

export function getUser() {
	return getObjectFromStorage(userKey)
}

export function getMFADetails(token) {
	const decoded = token && jwt_decode(token)
	const {qrCode = '', secret = ''} = decoded
	return {qrCode, secret}
}

export function getUserDetails(token) {
	const decoded = token && jwt_decode(token)
	const {email = '', firstName = '', lastName = '', tokenExpiry = ''} = decoded
	return {email, firstName, lastName, tokenExpiry}
}

export function getUserGroupId() {
	const token = getAuthToken()
	const decoded = token && jwt_decode(token)
	return decoded && decoded['groupId']
}

export function getUserId() {
	const token = getAuthToken()
	const decoded = token && jwt_decode(token)
	return decoded && decoded['userId']
}

export function getCounterpartyId() {
	const token = getAuthToken()
	const decoded = token && jwt_decode(token)
	return decoded && decoded['tenantId']
}

export function getCounterpartyTenantType() {
	const token = getAuthToken()
	const decoded = token && jwt_decode(token)
	return decoded && decoded.tenantType
}

export function setPermissions() {
	const token = getAuthToken()
	const decoded = token && jwt_decode(token)
	let permissionList = []
	const roles = decoded && decoded['roles']
	if (roles) {
		permissionList = JSON.parse(roles)
	}
	setPermissionsInStorage(permissionList)
}

export function isInternalUser() {
	const token = getAuthToken()
	const decoded = token && jwt_decode(token)
	return decoded && decoded.isInternal === 'true'
}

export function isUserExternalIdp() {
	const token = getAuthToken()
	const decoded = token && jwt_decode(token)
	return decoded && decoded.isExternalIdp === 'true'
}

export function getModuleGroupMap() {
	const token = getAuthToken()
	const decoded = token && jwt_decode(token)
	return decoded && decoded.moduleGroupMap && JSON.parse(decoded.moduleGroupMap)
}

export function getUsername() {
	const token = getAuthToken()
	const decoded = token && jwt_decode(token)
	return decoded && decoded.username
}
