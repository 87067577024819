import React from 'react'
import {Formik} from 'formik'
import * as yup from 'yup'
import {history} from '../../../store/mainStore'
import FsTextField from '../../../common/formItems/fsTextField'
import '../../login/login.scss'
import {forgotPasswordTitle} from '../forgotPasswordConstant'
import {redirectLoginWithTenantCode} from '../../../utils/amplify'

interface Props {
	onSubmit: Function
}

interface State {}

export default class PasswordResetForm extends React.Component<Props, State> {
	render() {
		const {onSubmit} = this.props

		const validationSchema = yup.object().shape({
			otp: yup.number().required('OTP is required'),
			newPassword: yup.string().required('password is required'),
			confirmPassword: yup
				.string()
				.required('password is required')
				.test('match', 'password do not match', function (confirmPassword) {
					return confirmPassword === this.parent.newPassword
				})
				.matches(
					/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
					'Must Contain atleast 8 Characters, and atleast One Uppercase, One Lowercase, One Number and One Special Case Character'
				),
		})

		return (
			<Formik
				validationSchema={validationSchema}
				initialValues={{
					otp: '',
					newPassword: '',
					confirmPassword: '',
				}}
				onSubmit={values => {
					values.otp = String(values.otp)
					onSubmit(values)
				}}
			>
				{({values, errors, handleSubmit, handleChange, handleBlur}) => (
					<form onSubmit={handleSubmit}>
						<FsTextField
							id='otp'
							autoComplete='off'
							label='OTP'
							placeholder='Enter OTP'
							type='number'
							className='px-3 override-number-input'
							onChange={handleChange}
							value={values.otp}
							onBlur={handleBlur}
						/>
						<FsTextField
							id='newPassword'
							autoComplete='off'
							label='New password'
							placeholder='Enter new password'
							type='password'
							className='px-3'
							onChange={handleChange}
							value={values.newPassword}
							onBlur={handleBlur}
						/>
						<FsTextField
							id='confirmPassword'
							autoComplete='off'
							label='Confirm new password'
							placeholder='Confirm new password'
							type='password'
							className='px-3'
							onChange={handleChange}
							value={values.confirmPassword}
							onBlur={handleBlur}
						/>
						<button
							id='default_FsContainedButton'
							type='submit'
							className='btn btn-secondary btn-xl d-block w-100 mt-4 mb-4'
						>
							{forgotPasswordTitle.passwordReset}
						</button>
						<div className='d-flex'>
							<span
								onClick={() => {
									history && redirectLoginWithTenantCode(history)
								}}
								className='link f-14 d-block text-center w-100 f-16'
							>
								Cancel
							</span>
						</div>
					</form>
				)}
			</Formik>
		)
	}
}
