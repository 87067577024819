import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import Homepage from './HomepageComponent'
import {MainState} from '../../../store/mainReducer'
import {setNotificationData} from '../../toastNotification/toastNotificationActions'
import {getAllDataVisualizationDetails} from '../../../common/DataVisualization/DataVisualizationAction'
import {updateDataVisualizationDetails} from '../../../common/DataVisualization/DataVisualizationAction'

const mapStateToProps = (state: MainState) => {
	return {
		configData: state.dataVisualization.configData,
		loading: state.dataVisualization.loading,
		darkTheme: state.app.darkTheme,
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return bindActionCreators(
		{
			setNotificationData,
			getAllDataVisualizationDetails,
			updateDataVisualizationDetails,
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(Homepage)
