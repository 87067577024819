import {createFetchActions} from '../../services/createActions'
import {getPMSBaseUrl, getPMSGraphBaseUrl} from '../../services/apiService'
import {post, graphQlWithAxios, put} from '../../services/createAPICall'
import {getPMSClient} from '../..'
import {fetchDMNTemplateBasedOnId, fetchAllDMNTemplatesQuery, fetchCollateralScheduleClients} from './DmnViewerQueries'

export const SAVE_DMN_TEMPLATE = createFetchActions('SAVE_DMN_TEMPLATE')
export const LOAD_DMN_TEMPLATE = createFetchActions('LOAD_DMN_TEMPLATE')
export const FETCH_DMN_TEMPLATE = createFetchActions('FETCH_DMN_TEMPLATE')
export const UPDATE_DMN_TEMPLATE = createFetchActions('UPDATE_DMN_TEMPLATE')
export const FETCH_COLLATERAL_SCHEDULE_CLIENTS = createFetchActions('FETCH_COLLATERAL_SCHEDULE_CLIENTS')
export const GET_TEMPLATE_BY_CLIENT = createFetchActions('GET_TEMPLATE_BY_CLIENT')

export const saveDMNTemplate = (data: any) => (dispatch: any) => {
	return dispatch(
		post(
			SAVE_DMN_TEMPLATE,
			`${getPMSBaseUrl()}/v1/dmn/templates`,
			data,
			null,
			'Error occured while saving DMN template'
		)
	)
}

export const updateDMNTemplate = (data: any, id: any) => (dispatch: any) => {
	return dispatch(
		put(
			UPDATE_DMN_TEMPLATE,
			`${getPMSBaseUrl()}/v1/dmn/templates/${id}/`,
			data,
			null,
			'Error occured while updating DMN template'
		)
	)
}

export const loadDMNTemplateBasedOnId = id => {
	return function (dispatch) {
		dispatch({
			type: LOAD_DMN_TEMPLATE.triggered,
		})
		getPMSClient()
			.query({
				query: fetchDMNTemplateBasedOnId(id),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: LOAD_DMN_TEMPLATE.succeeded,
					response,
				})
			})
	}
}
export const fetchAllDMNTemplates = () => {
	return function (dispatch) {
		dispatch({
			type: FETCH_DMN_TEMPLATE.triggered,
		})
		getPMSClient()
			.query({
				query: fetchAllDMNTemplatesQuery(),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_DMN_TEMPLATE.succeeded,
					response,
				})
			})
	}
}

export const getCollateralScheduleClients = () => {
	const query = fetchCollateralScheduleClients()
	const baseURL = `${getPMSGraphBaseUrl()}`
	return function (dispatch) {
		graphQlWithAxios(dispatch, FETCH_COLLATERAL_SCHEDULE_CLIENTS, baseURL, query, response => {
			dispatch({
				type: FETCH_COLLATERAL_SCHEDULE_CLIENTS.succeeded,
				response:
					response.data &&
					response.data.data &&
					response.data.data.collateralScheduleDropDowns &&
					response.data.data.collateralScheduleDropDowns.clientDetailsShortNames,
			})
		})
	}
}
