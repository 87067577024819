import moment from 'moment-timezone'
import {ArrayUtil} from 'helper-util'
import {getTimeZone} from '../services/clientConfigurationService'

// migrated
export const getTimeZoneFormattedDate = () => {
	const timeZone = getTimeZone()
	return moment.utc().tz(timeZone).format('YYYYMMDD')
}

export const getISODateFormat = () => {
	return moment().toISOString()
}
// migrated
export const getDateTimeBasedOnTimeZone = () => {
	const timeZone = getTimeZone()
	return `${moment().tz(timeZone).format('YYYY-MM-DD HH:mm:ss')} (${moment.tz(timeZone).zoneAbbr()})`
}

// migrated
export function formatResponseTime(date) {
	const timeZone = getTimeZone()
	return date && timeZone ? `${moment.utc(date).tz(timeZone).format('h:mm A')} (${moment.tz(timeZone).zoneAbbr()})` : ''
}

// migrated
export function recentLogTime(date: String) {
	const timeZone = getTimeZone()
	return date && timeZone ? moment.utc(date).tz(timeZone).format('MMM Do YYYY, ddd') : ''
}

// migrated
export function convertUTCDateToLocalTime(date) {
	const timeZone = getTimeZone()
	return date && timeZone ? moment.utc(date).tz(timeZone).format(' h:mm:ss A') : ''
}

// migrated
export function formatDate(date) {
	return moment(date).isValid() ? moment(date).format('YYYY-MM-DD') : date
}

// migrated
export function formatDateForPattern(date: String, pattern: string) {
	const timeZone = getTimeZone()
	return date && timeZone ? moment.utc(date).tz(timeZone).format('MMM DD YYYY') : ''
}

export const formatDateArray = (dateArray: number[]) => {
	const [year, month, day, hour, minute, second] = dateArray
	const date = new Date(year, month - 1, day, hour, minute, second)
	return formatWithDateAndTime(date)
}

// migrated
export const formatWithDateAndTime = date => {
	const timeZone = getTimeZone()
	return date && moment(date).isValid()
		? timeZone
			? `${moment.utc(date).tz(timeZone).format('YYYY-MM-DD HH:mm:ss')} (${moment.tz(timeZone).zoneAbbr()})`
			: `${moment.utc(date).format('YYYY-MM-DD HH:mm:ss')} (${moment.tz(moment.tz.guess()).zoneAbbr()})`
		: date
}

// migrated
export const formatWithTime = date => {
	const timeZone = getTimeZone()
	return date && moment(date).isValid()
		? timeZone
			? moment.utc(date).tz(timeZone).format('HH:mm:ss')
			: moment.utc(date).format('HH:mm:ss')
		: date
}

// migrated - check
export function convertDatetoIsoString(date) {
	return moment(date).toDate().toISOString()
}

// migrated - check
export function formatDateWith0s(date) {
	if (date && date.toString().length === 1) {
		return `0${date}`
	} else return `${date}`
}

// migrated
export const getDaysDifference = (finalDate: any, initialDate: any) => {
	return moment(finalDate.format('L')).diff(moment(initialDate.format('L')), 'days')
}

// to be migrated
export function getDateFromArray(date) {
	if (ArrayUtil.isEmpty(date)) {
		return moment()
	} else {
		const dateInUTCFormat = new Date(Date.UTC(date[0], date[1] - 1, date[2]))
		return moment.utc(dateInUTCFormat)
	}
}

// to be migrated
export function getFormattedDisplayTime(date) {
	const dateInUTCFormat = new Date(
		Date.UTC(date.value[0], date.value[1] - 1, date.value[2], date.value[3], date.value[4], date.value[5])
	)
	const timeZone = getTimeZone()
	return date && timeZone ? moment(dateInUTCFormat).tz(timeZone).format('h:mm:ss a') : ''
}

// to be migrated
export function getWeekDayAfterTwoWorkingDays(date) {
	var day = moment(date).day()
	var givenDate = moment(date)
	switch (day) {
		case 0:
		case 1:
		case 2:
		case 5:
			return moment(givenDate).add(3, 'days')
		case 3:
		case 6:
			return moment(givenDate).add(4, 'days')
		case 4:
			return moment(givenDate).add(5, 'days')
	}
}

// to be migrated
export function getWeekDayAfterOneWorkingDays(date) {
	var day = moment(date).day()
	var givenDate = moment(date)
	switch (day) {
		case 0:
		case 1:
		case 2:
		case 3:
			return moment(givenDate).add(2, 'days')
		case 6:
			return moment(givenDate).add(3, 'days')
		case 4:
		case 5:
			return moment(givenDate).add(4, 'days')
	}
}

// to be migrated
export function getWeekDay(date) {
	var day = moment(date).day()
	var givenDate = moment(date)
	while (day === 0 || day === 6) {
		givenDate = moment(givenDate).add(1, 'days')
		day = moment(givenDate).day()
	}
	return givenDate
}
