import moment from 'moment-timezone'
export const formReducer = (state, action) => {
	switch (action.type) {
		case 'UPDATE_FORM':
			const {name, val} = action.data
			return {
				...state,
				// update the state of the particular field,
				// by retaining the state of other fields
				[name]: {...state[name], val},
			}
		default:
			return state
	}
}

export const onChange = (name: string, val: any, dispatch) => {
	dispatch({
		type: 'UPDATE_FORM',
		data: {name, val},
	})
}

export const disableCreateBtn = (mandatoryFields, data) =>
	mandatoryFields.some(field => data[field] === null || (typeof data[field] === 'number' && data[field] === 0))

export const roundUpToQuater = value => {
	return (Math.round(value * 4) / 4).toFixed(2)
}

export const updateDateFormat = details => {
	for (const key of Object.keys(details)) {
		const date = details[key]
		if (moment.isMoment(date)) details[key] = moment(date).format('YYYY-MM-DD')
	}

	return details
}
