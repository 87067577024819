import {AdditionalDetails} from './AdditionalDetails/AdditionalDetailsComponent'
// import {Indemnification} from './Indemnification/IndemnificationComponent'
import {OrderDetails} from './OrderDetails/OrderDetailsComponent'
import {getDateFromArray} from '../../../utils/dateUtil'

export const defaultState = {
	orderDetails: {
		orderIndicatorType: null,
		clientRiskType: null,
		clientIds: null,
		strategy: null,
		rateType: null,
		allInRate: null,
		benchmark: null,
		referenceId: null,
		spread: null,
		termStructure: null,
		collateralType: null,
		singleStock: null,
		collateralBasket: null,
		bidOffer: null,
		schedule: null,
		substitutable: null,
		quantity: null,
		unit: null,
		price: null,
		haircut: null,
		dirtyPrice: null,
		startCash: null,
		repoInterest: null,
		endCash: null,
		notional: null,
		currency: null,
		tradeDate: getDateFromArray([]),
		forwardStart: null,
		settlementDate: getDateFromArray([]),
		maturityDate: getDateFromArray([]),
		terminationDate: getDateFromArray([]).clone().add(1, 'd'),
		maturityType: null,
		resetType: null,
		refRate: null,
		legalEntityExternalId: null,
		minPiece: null,
		minIncrement: null,
		minLotSize: null,
	},
	indemnification: {
		indemnifier: null,
		indemnificationPrice: null,
		isIndemnificationRequested: null,
	},
	additionalDetails: {
		assetType: null,
		assetSubType: null,
		funds: null,
		index: null,
		ratings: null,
		sectors: null,
	},
}

export const field = {
	ORDER_TYPE: 'orderIndicatorType',
	BID_OFFER: 'bidOffer',
	COMMENTS: 'ordercomments',
	DURATION: 'duration',
	MATURITY_TYPE: 'maturityType',
	MATURITY_DATE: 'maturityDate',
	CURRENCY: 'currency',
	NOTIONAL: 'notional',
	SUBSTITUTABLE: 'substitutable',
	START_CASH: 'startCash',
	SETTLEMENT_DATE: 'settlementDate',
	TRADE_DATE: 'tradeDate',
	FORWARD_START: 'forwardStart',
	STRATEGY: 'strategy',
	RESET_TYPE: 'resetType',
	TERM_STRUCTURE: 'termStructure',
	STEPS: 'steps',
	FLOATINGRATECOMPRESSUI: 'floatingRateCompressedUI',
	CURRENCIES: 'currencies',
	SCHEDULE: 'schedule',
	RATE_TYPE: 'rateType',
	CLIENT_RISK_TYPE: 'clientRiskType',
	CLIENT_IDS: 'clientIds',
	COLLATERAL_TYPE: 'collateralType',
	CONTRACT_TYPE: 'clientContractType',
	LEGAL_ENTITY_EXTERNAL_ID: 'legalEntityExternalId',
	COMMENTSTRADE: 'tradeComments',
}

export const steps = [
	{
		id: 'orderDetails',
		label: 'Order Details',
		modalBody: OrderDetails,
		onChange: 'setOrderDetails',
		mandatory: {
			REPO: [],
			P2P: [
				field.CONTRACT_TYPE,
				field.BID_OFFER,
				field.MATURITY_TYPE,
				field.NOTIONAL,
				field.CURRENCY,
				field.DURATION,
				field.TRADE_DATE,
				field.MATURITY_DATE,
				field.SETTLEMENT_DATE,
				field.SCHEDULE,
				field.RATE_TYPE,
				field.STRATEGY,
				field.CLIENT_IDS,
				field.CLIENT_RISK_TYPE,
				field.COLLATERAL_TYPE,
				field.LEGAL_ENTITY_EXTERNAL_ID,
				field.START_CASH,
			],
			FICC: [
				field.BID_OFFER,
				field.CURRENCY,
				field.MATURITY_TYPE,
				field.TRADE_DATE,
				field.MATURITY_DATE,
				field.SETTLEMENT_DATE,
				field.SCHEDULE,
				field.RATE_TYPE,
				field.STRATEGY,
				field.CLIENT_IDS,
				field.CLIENT_RISK_TYPE,
				field.COLLATERAL_TYPE,
				field.LEGAL_ENTITY_EXTERNAL_ID,
			],
			GCF: [
				field.BID_OFFER,
				field.CURRENCY,
				field.MATURITY_TYPE,
				field.TRADE_DATE,
				field.MATURITY_DATE,
				field.SETTLEMENT_DATE,
				field.SCHEDULE,
				field.RATE_TYPE,
				field.STRATEGY,
				field.CLIENT_IDS,
				field.CLIENT_RISK_TYPE,
				field.COLLATERAL_TYPE,
				field.LEGAL_ENTITY_EXTERNAL_ID,
			],
		},
	},
	// {
	// 	id: 'indemnification',
	// 	label: 'Indemnification',
	// 	modalBody: Indemnification,
	// 	onChange: 'setIndemnification',
	//  mandatory: {
	//     REPO: [],
	//     P2P: [],
	//     FICC: [],
	//     GCF: []
	//   }
	// },
	{
		id: 'additionalDetails',
		label: 'Additional Details',
		modalBody: AdditionalDetails,
		onChange: 'setAdditionalDetails',
		mandatory: {
			REPO: [],
			P2P: [],
			FICC: [],
			GCF: [],
		},
	},
]
