import {createFetchActions} from '../../services/createActions'

export const START_LOADING = createFetchActions('START_LOADING')
export const STOP_LOADING = createFetchActions('STOP_LOADING')

export const startLoading = () => {
	return {
		type: START_LOADING,
	}
}

export const stopLoading = () => {
	return {
		type: STOP_LOADING,
	}
}
