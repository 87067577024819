import memoizeOne from 'memoize-one'
import isDeepEqual from 'lodash.isequal'
import {ArrayUtil, FormatUtil} from 'helper-util'
import {menuValues} from '../customiseMenu/customiseMenuConstants'
import {checkFeaturePermission, checkRoutePermission} from '../../utils/featureUtils'
import {permission} from '../../constants/permission'
import {getPermissionsFromStorage} from '../../services/clientConfigurationService'

export const getUserName = memoizeOne(user => {
	if (!user) return ''

	const {email = '', firstName = '', lastName = ''} = user
	let initials = email ? email[0] : ''
	if (firstName) initials = firstName[0]
	if (firstName && lastName) initials = firstName[0] + lastName[0]
	return FormatUtil.text.toUpperCase(initials)
}, isDeepEqual)

export const getMenuOptions = memoizeOne(activeMenu => {
	const menuOptions = []
	!ArrayUtil.isEmpty(activeMenu) &&
		activeMenu.forEach(entry => {
			if (entry.isActive) {
				menuOptions.push(...entry.items)
			}
		})
	return Array.from(new Set(menuOptions))
})

export const isExecutionNotificationPermission = () => {
	return (
		checkFeaturePermission(permission.READ_P2P) ||
		checkFeaturePermission(permission.WRITE_P2P) ||
		checkFeaturePermission(permission.READ_AVAILABILITY_NEEDS_DOCUMENT) ||
		checkFeaturePermission(permission.WRITE_AVAILABILITY_NEEDS_DOCUMENT) ||
		checkFeaturePermission(permission.READ_SBL) ||
		checkFeaturePermission(permission.WRITE_SBL) ||
		checkFeaturePermission(permission.READ_REPO) ||
		checkFeaturePermission(permission.WRITE_REPO)
	)
}

export const getMenuOptionsCount = memoizeOne(
	(disableThemeSwitch, isIntlUser, isTenantExtIdp, navBarConfig, disableDocumentation) => {
		let menuOptionsCount = 1
		!disableThemeSwitch && menuOptionsCount++
		isIntlUser && checkRoutePermission(navBarConfig.accountSettings) && menuOptionsCount++
		if (!isTenantExtIdp) menuOptionsCount = menuOptionsCount + 2
		if (!disableDocumentation) menuOptionsCount += 2
		return menuOptionsCount
	}
)

export const getNavBarConfig = memoizeOne(menuOptions => {
	const navBarConfig = []
	menuOptions &&
		menuOptions
			.filter(menuOption => {
				const menuItem = menuValues[menuOption] || {}
				return checkRoutePermission(menuItem.path)
			})
			.forEach((item, index) => {
				const menuItem = menuValues[item] || {}
				const menuConfig = {
					count: index,
					label: menuItem.label,
					path: menuItem.path,
					icon: menuItem.icon,
					isSubRoute: false,
				}

				navBarConfig.push(menuConfig)
			})
	return navBarConfig
})

export const getFilteredPermissionMenu = navBarConfig => {
	const permissionList = getPermissionsFromStorage()

	return navBarConfig
		.map(config => {
			let validSubMenu = (config.subMenu || []).filter(subMenu =>
				ArrayUtil.isEmpty(subMenu.permission)
					? true
					: subMenu.permission.some(v => (!ArrayUtil.isEmpty(permissionList) ? permissionList.includes(v) : false))
			)

			if (!validSubMenu.some(sMenu => sMenu.isAggregate)) {
				validSubMenu = validSubMenu.filter(sMenu => sMenu.id !== 'group')
			}

			if (config.subMenu && ArrayUtil.isEmpty(validSubMenu)) {
				return null
			}
			if (!config.subMenu) {
				return config
			} else {
				return {
					...config,
					subMenu: validSubMenu,
				}
			}
		})
		.filter(config => !!config)
}
