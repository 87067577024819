import {getMPSBaseUrl} from '../../services/apiService'
import {createFetchActions} from '../../services/createActions'
import {get, post, del} from '../../services/createAPICall'

export const CREATE_BASKET = createFetchActions('CREATE_BASKET')
export const CLEAR_DYNAMIC_FORM_DETAIL = createFetchActions('CLEAR_DYNAMIC_FORM_DETAIL')
export const CLEAR_PRODUCT_TYPE_SUBTYPE = createFetchActions('CLEAR_PRODUCT_TYPE_SUBTYPE')
export const GET_DYNAMIC_FORM_DETAIL = createFetchActions('GET_DYNAMIC_FORM_DETAIL')
export const GET_ALL_BASKETS = createFetchActions('GET_ALL_BASKETS')
export const GET_ALL_MARGIN_COMPARISON = createFetchActions('GET_ALL_MARGIN_COMPARISON')
export const GET_ALL_TRADES = createFetchActions('GET_ALL_TRADES')
export const UPDATE_TRADE_BASKET = createFetchActions('UPDATE_TRADE_BASKET')
export const ADD_FORECAST_TRADES = createFetchActions('ADD_FORECAST_TRADES')
export const CLEAR_TRADE_BASKET = createFetchActions('CLEAR_TRADE_BASKET')
export const EDIT_DYNAMIC_FORM = createFetchActions('EDIT_DYNAMIC_FORM')
export const GET_SAVED_TRADES = createFetchActions('GET_SAVED_TRADES')
export const GET_ALL_SOD_TRADES = createFetchActions('GET_ALL_SOD_TRADES')
export const DOWNLOAD_TRADE_DOCUMENT = createFetchActions('DOWNLOAD_TRADE_DOCUMENT')
export const GET_MARGIN_COMPARISON = createFetchActions('GET_MARGIN_COMPARISON')
export const GET_PRODUCT_TYPE_AND_SUB_TYPE = createFetchActions('GET_PRODUCT_TYPE_AND_SUB_TYPE')
export const GET_PRODUCT_RISK_CLASSES = createFetchActions('GET_PRODUCT_RISK_CLASSES')
export const INVOKE_MARGIN_COMPARISON = createFetchActions('INVOKE_MARGIN_COMPARISON')
export const SAVE_DYNAMIC_FORM_DETAIL = createFetchActions('SAVE_DYNAMIC_FORM_DETAIL')
export const SET_MARGIN_COMPARISON_CONTEXT = createFetchActions('SET_MARGIN_COMPARISON_CONTEXT')
export const DELETE_TRADE_BY_ID = createFetchActions('DELETE_TRADE_BY_ID')

export const getProductRiskClasses = () => (dispatch: any) => {
	return dispatch(get(GET_PRODUCT_RISK_CLASSES, `${getMPSBaseUrl()}/v1/margin-analytics/products/risk-classes`))
}

export const getProductTypeAndSubTypeBasedOnRiskClasses = (riskClasses: string) => (dispatch: any) => {
	return dispatch(
		get(GET_PRODUCT_TYPE_AND_SUB_TYPE, `${getMPSBaseUrl()}/v1/margin-analytics/products?risk_class=${riskClasses}`)
	)
}

export const getProductTypeAndSubType = () => (dispatch: any) => {
	return dispatch(get(GET_PRODUCT_TYPE_AND_SUB_TYPE, `${getMPSBaseUrl()}/v1/margin-analytics/products`))
}

export const clearDynamicFormDetail = () => {
	return {type: CLEAR_DYNAMIC_FORM_DETAIL}
}

export const clearProductTypeAndSubType = () => {
	return {type: CLEAR_PRODUCT_TYPE_SUBTYPE}
}

export const getDynamicFormDetail =
	(
		productType: string,
		productSubType: string,
		selectedFundCode: string = '',
		selectedPortfolioCode: string = '',
		tradetype1Val: string = '',
		tradeType2Val: string = ''
	) =>
	(dispatch: any) => {
		const url = `${getMPSBaseUrl()}/v1/margin-analytics/products/definitions/${productType}/${productSubType}?fund_code=${selectedFundCode}&portfolio_code=${selectedPortfolioCode}&trade_type_1=${tradetype1Val}&trade_type_2=${tradeType2Val}`
		return dispatch(get(GET_DYNAMIC_FORM_DETAIL, url))
	}

export const saveDynamicFormDetail = (payload: any) => (dispatch: any) => {
	return dispatch(post(SAVE_DYNAMIC_FORM_DETAIL, `${getMPSBaseUrl()}/v1/margin-analytics/trades/create`, payload))
}

export const updateDynamicFormDetail = (tradeId: string, payload: any) => (dispatch: any) => {
	return dispatch(
		post(SAVE_DYNAMIC_FORM_DETAIL, `${getMPSBaseUrl()}/v1/margin-analytics/trades/update/${tradeId}`, payload)
	)
}

export const getAllTrades = () => (dispatch: any) => {
	return dispatch(get(GET_ALL_TRADES, `${getMPSBaseUrl()}/v1/margin-analytics/trades`))
}

export const getSavedTradeDetails = (tradeId: any) => (dispatch: any) => {
	return dispatch(get(GET_DYNAMIC_FORM_DETAIL, `${getMPSBaseUrl()}/v1/margin-analytics/trades/displayTrade/${tradeId}`))
}

export const getAllSODTrades = () => (dispatch: any) => {
	return dispatch(get(GET_ALL_SOD_TRADES, `${getMPSBaseUrl()}/v1/margin-analytics/trades/sodTrades`))
}

export const downlaodTradeDocument = (tradeId: number) => (dispatch: any) => {
	return dispatch(get(DOWNLOAD_TRADE_DOCUMENT, `${getMPSBaseUrl()}/v1/margin-analytics/trades/sodTrade/${tradeId}`))
}

export const getAllBaskets = () => (dispatch: any) => {
	return dispatch(get(GET_ALL_BASKETS, `${getMPSBaseUrl()}/v1/margin-analytics/trades-basket/all`))
}

export const createBasket = payload => (dispatch: any) => {
	return dispatch(post(CREATE_BASKET, `${getMPSBaseUrl()}/v1/margin-analytics/trades-basket/create`, payload))
}

export const getMarginComparison = (basketId: number) => (dispatch: any) => {
	return dispatch(get(GET_MARGIN_COMPARISON, `${getMPSBaseUrl()}/v1/margin-analytics/margin-comparison/${basketId}`))
}

export const getAllMarginComparison = (basketId: number) => (dispatch: any) => {
	return dispatch(get(GET_ALL_MARGIN_COMPARISON, `${getMPSBaseUrl()}/v1/margin-analytics/margin-comparison/all`))
}

export const invokeMarginComparison = (payload: any, fund_code: string) => (dispatch: any) => {
	return dispatch(
		post(
			INVOKE_MARGIN_COMPARISON,
			`${getMPSBaseUrl()}/v1/margin-analytics/margin-comparison/invoke?fund_code=${fund_code} `,
			payload
		)
	)
}

export const setComparisonDetailsInContext = payload => {
	return {
		type: SET_MARGIN_COMPARISON_CONTEXT,
		payload,
	}
}

export const deleteTradeByID = (tradeId: number) => (dispatch: any) => {
	return dispatch(del(DELETE_TRADE_BY_ID, `${getMPSBaseUrl()}/v1/margin-analytics/trades/${tradeId}`))
}

