import {portfolioEntities, dropdownOption} from '../../constants/dashboardConstants'
import {MaturingTrade, OpenTrade, PortfolioLongShort, BoxIcon} from '../../styles/svg/svgIcons'
import {paths} from '../../routes/routesConfig'

export const displayCards = {
	[portfolioEntities.all]: {
		icon: PortfolioLongShort,
		entity: portfolioEntities.all,
		dropdownDefault: [dropdownOption.counterParty.id, dropdownOption.securityId.id],
		modifier: 1,
		dualHeatmap: true,
		filterType: {
			toggle: [],
			dropdown: true,
		},
		titleText: 'Total Notional',
		tradeDetailRoutePath: paths.abcPortfolioTest,
		heatmapConfig: {
			allowDrilldown: true,
			linearGradientColor: [],
		},
		moveToQueue: false,
	},
	[portfolioEntities.matureTrades]: {
		icon: MaturingTrade,
		entity: portfolioEntities.matureTrades,
		dropdownDefault: [dropdownOption.counterParty.id, dropdownOption.securityId.id],
		modifier: 1,
		dualHeatmap: false,
		filterType: {
			toggle: [],
			dropdown: true,
		},
		titleText: 'Total Notional',
		tradeDetailRoutePath: paths.matureTrades,
		heatmapConfig: {
			allowDrilldown: true,
			linearGradientColor: [],
		},
		moveToQueue: false,
	},
	[portfolioEntities.openTrades]: {
		icon: OpenTrade,
		entity: portfolioEntities.openTrades,
		dropdownDefault: [dropdownOption.counterParty.id, dropdownOption.securityId.id],
		modifier: 1,
		dualHeatmap: false,
		filterType: {
			toggle: [],
			dropdown: true,
		},
		titleText: 'Total Notional',
		tradeDetailRoutePath: paths.openTrades,
		heatmapConfig: {
			allowDrilldown: true,
			linearGradientColor: [],
		},
		moveToQueue: false,
	},
	[portfolioEntities.boxes]: {
		icon: BoxIcon,
		entity: portfolioEntities.boxes,
		dropdownDefault: [dropdownOption.fund.id, dropdownOption.securityId.id],
		modifier: 1,
		dualHeatmap: true,
		filterType: {
			toggle: [],
			dropdown: true,
		},
		titleText: 'Total Notional',
		tradeDetailRoutePath: paths.boxes,
		heatmapConfig: {
			allowDrilldown: true,
			linearGradientColor: [],
		},
		moveToQueue: false,
	},
}
