// import React from 'react'
// import {columnDefinition, customColumnField} from '../../../../constants/columnDefinition'
// import {tableHeaderWithTitle, tableHeaderClassName} from '../../../../utils/reactTableUtil'
// import {ArrayUtil} from 'helper-util'
// import {formatWithSeparator} from '../../../../utils/formatUtils'
// import {InlineEditIcon} from '../../../../styles/svg/svgIcons'
import {globalColumnDefinition} from '../../../../common/AgGrid/AgGridColumn'

export const csvName = `incoming_request_entries`

export const viewFilter = {
	all: 'all',
	created: 'CREATED',
	acknowledged: 'ACKNOWLEDGED',
	negotiationReview: 'NEGOTIATION_REVIEW',
	negotiated: 'NEGOTIATED',
	acceptedReview: 'ACCEPTED_REVIEW',
	accepted: 'ACCEPTED',
	rejected: 'REJECTED',
}

export const viewList = [
	{label: 'All', id: viewFilter.all},
	{label: 'Created', id: viewFilter.created},
	{label: 'Acknowledged', id: viewFilter.acknowledged},
	{label: 'Negotiation Review', id: viewFilter.negotiationReview},
	{label: 'Negotiated', id: viewFilter.negotiated},
	{label: 'Accepted Review', id: viewFilter.acceptedReview},
	{label: 'Accepted', id: viewFilter.accepted},
	{label: 'Rejected', id: viewFilter.rejected},
]

export const p2pActions = {
	ACKNOWLEDGED: 'Acknowledged',
	ACCEPTED_REVIEW: 'Accepted',
	ACCEPTED: 'Accepted',
	NEGOTIATION_REVIEW: 'Negotiated',
	NEGOTIATED: 'Negotiated',
	REJECTED: 'Rejected',
	OPEN_OFFLINE: 'Created Offline',
}

export const getInitialColumns = (
	items,
	handleAcceptRejectAction,
	handleAcknowledgeAction,
	handleQuantityClick,
	handleAllInRateChange
) => {
	return [
		globalColumnDefinition.status,
		Object.assign({}, globalColumnDefinition.createdDate, {headerName: 'Recall/Return Time'}),
		Object.assign({}, globalColumnDefinition.actions, {
			cellRendererParams: {items, handleAcceptRejectAction, handleAcknowledgeAction},
			cellRenderer: 'Actions',
			minWidth: 250,
		}),
		Object.assign({}, globalColumnDefinition.allInRate, {
			cellRendererParams: {items, handleEditableCell: handleAllInRateChange},
			cellRenderer: 'AllInRate',
		}),
		Object.assign({}, globalColumnDefinition.recallReturnQuantity, {
			cellRendererParams: {items, handleQuantityClick},
			cellRenderer: 'RecallReturnQuantity',
		}),
		globalColumnDefinition.recallReturnNotional,
		globalColumnDefinition.portfolioEntryType,
		globalColumnDefinition.securityId,
		globalColumnDefinition.isRestricted,
		globalColumnDefinition.inefficienciesArray,
		globalColumnDefinition.quantity,
		globalColumnDefinition.priceAmount,
		globalColumnDefinition.basePriceAmount,
		globalColumnDefinition.notionalAmount,
		Object.assign({}, globalColumnDefinition.collateralAmount, {headerName: 'Recall/Return Collateral Amount'}),
		globalColumnDefinition.notionalCurrency,
		globalColumnDefinition.recallInefficiencyContributionAmount,
		globalColumnDefinition.returnInefficiencyContributionAmount,
		globalColumnDefinition.collateralType,
		globalColumnDefinition.collateralAmount,
		globalColumnDefinition.cusip,
		globalColumnDefinition.isin,
		globalColumnDefinition.ticker,
		globalColumnDefinition.originalTradeType,
		globalColumnDefinition.legalEntity,
		globalColumnDefinition.ric,
		globalColumnDefinition.bbId,
		globalColumnDefinition.priceCurrency,
		globalColumnDefinition.baseNotional,
		globalColumnDefinition.allInRate,
		globalColumnDefinition.spread,
		globalColumnDefinition.referenceRate,
		globalColumnDefinition.haircut,
		globalColumnDefinition.assetType,
		globalColumnDefinition.assetSubType,
		globalColumnDefinition.tradeType,
		globalColumnDefinition.maturityType,
		globalColumnDefinition.book,
		globalColumnDefinition.fund,
		globalColumnDefinition.buySellInd,
		globalColumnDefinition.counterParty,
		globalColumnDefinition.sector,
		globalColumnDefinition.index,
		globalColumnDefinition.moodyRating,
		globalColumnDefinition.snpRating,
		globalColumnDefinition.fitchRating,
		globalColumnDefinition.sourceSystem,
		globalColumnDefinition.sourceSystemTradeId,
		globalColumnDefinition.startDate,
		globalColumnDefinition.endDate,
		globalColumnDefinition.maturityDate,
		globalColumnDefinition.termDate,
		globalColumnDefinition.tradeDate,
		globalColumnDefinition.settlementDate,
	]
	// return [
	// 	columnDefinition.incomingEntryStatus,
	// 	columnDefinition.recallReturnTimeStamp,
	// 	{
	// 		Header: tableHeaderWithTitle('Action'),
	// 		accessor: 'actions',
	// 		Cell: props => {
	// 			const isAcknowledge = !ArrayUtil.isEmpty(props.value) && props.value.includes('ACKNOWLEDGE')
	// 			const isAccept = !ArrayUtil.isEmpty(props.value) && props.value.includes('ACCEPT')
	// 			const isReject = !ArrayUtil.isEmpty(props.value) && props.value.includes('REJECT')
	// 			const isNegotiate = !ArrayUtil.isEmpty(props.value) && props.value.includes('NEGOTIATE')
	// 			const hasActions = !ArrayUtil.isEmpty(props.value)
	// 			const actionLabel = props.original.orderId ? items[props.original.orderId] : ''
	// 			return hasActions ? (
	// 				<span className='btn-xs btn-group p-0'>
	// 					{isAccept && (
	// 						<button
	// 							className={`btn btn-tiny ml-2 ${actionLabel === 'ACCEPT' ? 'btn-secondary' : 'btn-outline-secondary'}`}
	// 							onClick={() => handleAcceptRejectAction(props.original.orderId, 'ACCEPT')}
	// 						>
	// 							ACCEPT
	// 						</button>
	// 					)}
	// 					{isReject && (
	// 						<button
	// 							className={`btn btn-tiny ml-1 ${actionLabel === 'REJECT' ? 'btn-danger' : 'btn-outline-danger'}`}
	// 							onClick={() => handleAcceptRejectAction(props.original.orderId, 'REJECT')}
	// 						>
	// 							REJECT
	// 						</button>
	// 					)}
	// 					{isNegotiate && (
	// 						<button
	// 							className={`btn btn-tiny ml-1 ${actionLabel === 'NEGOTIATE' ? 'btn-dark' : 'btn-outline-dark'}`}
	// 							onClick={() => handleAcceptRejectAction(props.original.orderId, 'NEGOTIATE')}
	// 						>
	// 							NEGOTIATE
	// 						</button>
	// 					)}
	// 					{isAcknowledge && (
	// 						<button
	// 							className={`btn btn-tiny ml-1 ${actionLabel === 'ACKNOWLEDGE' ? 'btn-primary' : 'btn-outline-primary'}`}
	// 							onClick={() => handleAcknowledgeAction(props.original.orderId, 'ACKNOWLEDGE')}
	// 						>
	// 							ACKNOWLEDGE
	// 						</button>
	// 					)}
	// 				</span>
	// 			) : (
	// 				''
	// 			)
	// 		},
	// 		minWidth: 225,
	// 	},
	// 	columnDefinition.portfolioEntryType,
	// 	columnDefinition.securityId,
	// 	columnDefinition.isRestricted,
	// 	columnDefinition[customColumnField.inefficienciesArray],
	// 	columnDefinition.originalQuantity,
	// 	columnDefinition.priceAmountV2,
	// 	columnDefinition.basePriceAmountV2,
	// 	columnDefinition.notionalAmountV2,
	// 	{
	// 		Header: tableHeaderWithTitle('Recalled/Returned Quantity'),
	// 		accessor: 'quantity',
	// 		minWidth: 160,
	// 		headerClassName: tableHeaderClassName(),
	// 		Cell: props => {
	// 			const canNegotiate = !ArrayUtil.isEmpty(props.original.actions) && props.original.actions.includes('NEGOTIATE')
	// 			const isNegotiateClicked = items[props.original.orderId] === 'NEGOTIATE'
	// 			const quantity = props.value
	// 			return typeof quantity !== 'number' ? (
	// 				<div className='overflow-ellipsis w-100 text-right mr-2'>{quantity}</div>
	// 			) : (
	// 				<>
	// 					<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(quantity))}</div>
	// 					{isNegotiateClicked && (
	// 						<span
	// 							className='text-primary d-flex pr-1'
	// 							onClick={() => {
	// 								canNegotiate && isNegotiateClicked && handleQuantityClick(props.original.orderId)
	// 							}}
	// 						>
	// 							<InlineEditIcon />
	// 						</span>
	// 					)}
	// 				</>
	// 			)
	// 		},
	// 	},
	// 	columnDefinition.recallReturnNotional,
	// 	columnDefinition.collateralAmountV3,
	// 	columnDefinition.notionalCurrencyV2,
	// 	columnDefinition.recallInefficiencyContributionAmountV2,
	// 	columnDefinition.returnInefficiencyContributionAmountV2,
	// 	columnDefinition.collateralType,
	// 	columnDefinition.originalCollateralValueAmountV2,
	// 	columnDefinition.cusip,
	// 	columnDefinition.isin,
	// 	columnDefinition.ticker,
	// 	columnDefinition.ric,
	// 	columnDefinition.bbId,
	// 	columnDefinition.priceCurrencyV2,
	// 	columnDefinition.baseNotionalv2,
	// 	columnDefinition.allInRate,
	// 	columnDefinition.spread,
	// 	columnDefinition.referenceRate,
	// 	columnDefinition.haircut,
	// 	columnDefinition.assetType,
	// 	columnDefinition.assetSubType,
	// 	columnDefinition.tradeType,
	// 	columnDefinition.maturityType,
	// 	columnDefinition.book,
	// 	columnDefinition.fund,
	// 	columnDefinition.buySellInd,
	// 	columnDefinition.counterParty,
	// 	columnDefinition.sector,
	// 	columnDefinition.index,
	// 	columnDefinition.moodyRating,
	// 	columnDefinition.snpRating,
	// 	columnDefinition.fitchRating,
	// 	columnDefinition.sourceSystem,
	// 	columnDefinition.sourceSystemTradeId,
	// 	columnDefinition.startDate,
	// 	columnDefinition.endDate,
	// 	columnDefinition.maturityDate,
	// 	columnDefinition.termDate,
	// 	columnDefinition.tradeDate,
	// 	columnDefinition.settlementDate,
	// ]
}
