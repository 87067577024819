export const fieldOption = {
	ORDER_TYPE: {
		IOI: 'IOI',
		RFQ: 'RFQ',
	},
	MATURITY_TYPE: {
		CALLABLE: 'Callable',
		EVERGREEN: 'Evergreen',
		EXTENDABLE: 'Extendable',
		OPEN: 'Open',
		PUTTABLE: 'Puttable',
		TERM: 'Term',
		OVERNIGHT: 'Overnight',
	},
	STRATEGY: {
		ASSET_SWITCH: 'ASSET_SWITCH',
		COLLATERAL_DOWNGRADE: 'COLLATERAL_DOWNGRADE',
		COLLATERAL_UPGRADE: 'COLLATERAL_UPGRADE',
		ETF_CREATION_REDEMPTION: 'ETF_CREATION_REDEMPTION',
		INDEX: 'INDEX',
		PURPOSE_BORROW: 'PURPOSE_BORROW',
		REPO: 'REPO',
		SBL: 'SBL',
		SWAP: 'SWAP',
	},
}

export const strategyOption = [
	{id: fieldOption.STRATEGY.ASSET_SWITCH, label: 'Asset Switch', value: 'ASSET_SWITCH'},
	{id: fieldOption.STRATEGY.COLLATERAL_DOWNGRADE, label: 'Collateral Downgrade', value: 'COLLATERAL_DOWNGRADE'},
	{id: fieldOption.STRATEGY.COLLATERAL_UPGRADE, label: 'Collateral Upgrade', value: 'COLLATERAL_UPGRADE'},
	{
		id: fieldOption.STRATEGY.ETF_CREATION_REDEMPTION,
		label: 'ETF Creation/Redemption',
		value: 'ETF_CREATION_REDEMPTION',
	},
	{id: fieldOption.STRATEGY.INDEX, label: 'Index', value: 'INDEX'},
	{id: fieldOption.STRATEGY.PURPOSE_BORROW, label: 'Purpose Borrow', value: 'PURPOSE_BORROW'},
	{id: fieldOption.STRATEGY.REPO, label: 'Repo', value: 'REPO'},
	{id: fieldOption.STRATEGY.SBL, label: 'SBL', value: 'SBL'},
	{id: fieldOption.STRATEGY.SWAP, label: 'Swap', value: 'SWAP'},
]

export const bidOfferType = {
	cash: 'CASH',
	collateral: 'COLLATERAL',
	decideLater: 'DECIDE_LATER',
	singleStock: 'SINGLE_SECURITY',
}

export const cashSingleStockOption = [
	{
		id: 'repo',
		label: 'BID - Collateral',
		value: {
			bidType: bidOfferType.singleStock,
			offerType: bidOfferType.cash,
		},
	},
	{
		id: 'reverseRepo',
		label: 'OFFER - Collateral',
		value: {
			bidType: bidOfferType.cash,
			offerType: bidOfferType.singleStock,
		},
	},
]

export const cashCollateralOption = [
	{
		id: 'repo',
		label: 'BID - Collateral',
		value: {
			bidType: bidOfferType.collateral,
			offerType: bidOfferType.cash,
		},
	},
	{
		id: 'reverseRepo',
		label: 'OFFER - Collateral',
		value: {
			bidType: bidOfferType.cash,
			offerType: bidOfferType.collateral,
		},
	},
]

export const currencyOption = [
	{id: 'usd', label: 'USD', value: 'USD'},
	{id: 'gpb', label: 'GBP', value: 'GBP'},
	{id: 'eur', label: 'EUR', value: 'EUR'},
	{id: 'cad', label: 'CAD', value: 'CAD'},
	{id: 'jpy', label: 'JPY', value: 'JPY'},
	{id: 'aud', label: 'AUD', value: 'AUD'},
	{id: 'chf', label: 'CHF', value: 'CHF'},
]

export const maturityOption = [
	{id: fieldOption.MATURITY_TYPE.CALLABLE, label: 'Callable', value: 'Callable'},
	{id: fieldOption.MATURITY_TYPE.EVERGREEN, label: 'Evergreen', value: 'Evergreen'},
	{id: fieldOption.MATURITY_TYPE.EXTENDABLE, label: 'Extendable', value: 'Extendable'},
	{id: fieldOption.MATURITY_TYPE.OPEN, label: 'Open', value: 'Open'},
	{id: fieldOption.MATURITY_TYPE.PUTTABLE, label: 'Puttable', value: 'Puttable'},
	{id: fieldOption.MATURITY_TYPE.TERM, label: 'Term', value: 'Term'},
	{id: fieldOption.MATURITY_TYPE.OVERNIGHT, label: 'Overnight', value: 'Overnight'},
]

export const unitOption = [
	{id: 'million', label: 'M (Million)', value: 1000000},
	{id: 'tenMillion', label: 'MM (Ten Million)', value: 10000000},
	{id: 'hundredMillion', label: 'MMM (Hundred Million)', value: 100000000},
	{id: 'billion', label: 'B (Billion)', value: 1000000000},
]
