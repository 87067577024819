import React from 'react'
import {Modal, Button} from 'react-bootstrap'

interface Props {
	size?: JSX.Element | any
	show?: boolean
	headerEl?: JSX.Element | any
	bodyEl?: JSX.Element | any
	footerEl?: JSX.Element | any
	isModalScrollable?: boolean
	backdrop?: any
	className?: string
	enforceFocus?: boolean
	onHide?: () => any
}

export default function VerticallyCenteredModal(props: Props) {
	const {
		className,
		size = 'lg',
		show = false,
		enforceFocus,
		onHide,
		isModalScrollable = false,
		headerEl = <h3>Header</h3>,
		bodyEl = <div>body</div>,
		footerEl = (
			<div>
				<Button onClick={onHide}>Close</Button>
			</div>
		),
		backdrop = true,
	} = props
	return (
		<Modal
			show={show}
			size={size}
			backdrop={backdrop}
			aria-labelledby='contained-modal-title-vcenter'
			centered
			onHide={onHide}
			dialogClassName={className}
			enforceFocus={enforceFocus}
			className={isModalScrollable ? 'modal-scrollable ' : ''}
		>
			{headerEl}
			{bodyEl}
			{footerEl}
		</Modal>
	)
}
