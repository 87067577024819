import * as React from 'react'
import DatePicker from 'react-datepicker'

interface Props {
	disabled?: boolean
	dateFormat?: string
	selected?: any
	minDate?: any
	popperPlacement?: string
	filterDate?: any
	className?: string
	onFocus?: (value: any) => any
	onChange?: (value: any) => any
}

export default class FsDatePicker extends React.Component<Props> {
	static defaultProps = {
		disabled: false,
	}

	render() {
		const {disabled, dateFormat, selected, minDate, className, popperPlacement, filterDate, onFocus, onChange} =
			this.props

		return (
			<DatePicker
				className={` ${disabled ? 'disabled-DatePicker cursor-not-allowed' : className}`}
				disabled={disabled}
				dateFormat={dateFormat}
				selected={selected}
				minDate={minDate}
				popperPlacement={popperPlacement}
				filterDate={filterDate}
				onFocus={onFocus}
				onChange={onChange}
			/>
		)
	}
}
