import React from 'react'
import Lodash from 'lodash'
import memoizeOne from 'memoize-one'
import * as Sentry from '@sentry/browser'
import isDeepEqual from 'lodash.isequal'
import {ArrayUtil, FormatUtil} from 'helper-util'

import {getHeaderValue} from './reactTableUtil'
import {getPermissionsFromStorage} from '../services/clientConfigurationService'
import {settlementLadderColors} from '../constants/colorValues'
import {getObjectFromStorage, setObjectInStorage, getStringFromStorage} from '../services/storageService'
import {activeClientConfig} from '../services/authService'
import {pivotMetrics, pivotViews} from '../pages/abcmResult/abcmResultConstants'
import {customColumnField} from '../constants/columnDefinition'
import {paths} from '../routes/routesConfig'
import {toggleBlankValue} from '../features/commonDashboard/dashboardHelper'
import {actionTypeMapping} from '../pages/tradingQueue/tradingQueueConstants'
import {commaTwoDecimal, commaNoDecimal} from '../common/AgGrid/AgGridHelper'

export const ORDER_STATUS = {
	AWAITING_CONFIRMATION: 'awaiting confirmation',
	CREATED: 'created',
	BID_APPLICABLE: 'bid applicable',
	ACCEPTED: 'accepted',
	CLOSED: 'closed',
	CONFIRMATION_REQUIRED: 'confirmation required',
	BID_ACCEPTED: 'bid accepted',
	BID_REJECTED: 'bid rejected',
	CANCELLED: 'cancelled',
}

export const getFilteredData = (data = [], searchText: string) => {
	if (searchText && Array.isArray(data) && data.length > 0) {
		return data.filter(item =>
			Object.values(item).some(
				(val: string) =>
					typeof val === 'string' && FormatUtil.text.toLowerCase(val).includes(FormatUtil.text.toLowerCase(searchText))
			)
		)
	}
	return data
}

export const getInefficiencyClassName = (abbr: string) => {
	const baseClass = 'badge badge-pill text-white'
	switch (abbr) {
		case 'Pr':
			return `${baseClass} bg-orange`
		case 'BS':
			return `${baseClass} bg-purple`
		case 'CA':
			return `${baseClass} bg-green`
		case 'LC':
			return `${baseClass} bg-light-blue`
		case 'SI':
			return `${baseClass} bg-pastel`
		case 'SR':
			return `${baseClass} bg-nephritis`
		case 'RI':
			return `${baseClass} bg-belize`
		case 'MT':
			return `${baseClass} bg-dark`
		case 'RR':
			return `${baseClass} bg-rajah`
		default:
			return `${baseClass} bg-flamingo`
	}
}

export const flattenObject = (parentKey, data) => {
	const lineItem = {}
	if (data && data.constructor === Object) {
		const keys = Object.keys(data)
		keys.forEach(item => {
			lineItem[`${parentKey}${FormatUtil.text.toTitleCase(item)}`] = data[item]
		})
		return lineItem
	}
	return null
}

export const flattenRow = item => {
	const itemKey = Object.keys(item)
	let newItem = item
	itemKey.forEach(key => {
		const splittedValue = flattenObject(key, item[key])
		if (splittedValue) newItem = Object.assign(newItem, splittedValue)
	})
	return newItem
}

export const renameKey = (object, oldKey, newKey) => {
	object[newKey] = object[oldKey]
	delete object[oldKey]
}

export const calibrateCalendarCellBg = (netCashflow, noOfTrades, assetType) => {
	let noOfTradesShade = 'one'
	if (noOfTrades > 10 && noOfTrades <= 50) {
		noOfTradesShade = 'two'
	} else if (noOfTrades > 50 && noOfTrades <= 100) {
		noOfTradesShade = 'three'
	} else if (noOfTrades > 100 && noOfTrades <= 200) {
		noOfTradesShade = 'four'
	} else if (noOfTrades > 200) {
		noOfTradesShade = 'five'
	}

	let bg = 'red'
	if (netCashflow > 0) {
		bg = 'green'
	} else if (netCashflow === 0) {
		bg = 'yellow'
	}

	return `bg-${bg}-shade-${noOfTradesShade}`
}

export const calibrateCalendarCellTextColor = (cashInFlow, cashOutFlow, noOfTrades) => {
	let noOfTradesShade = 'one'
	if (noOfTrades > 10 && noOfTrades <= 50) {
		noOfTradesShade = 'two'
	} else if (noOfTrades > 50 && noOfTrades <= 100) {
		noOfTradesShade = 'three'
	} else if (noOfTrades > 100 && noOfTrades <= 200) {
		noOfTradesShade = 'four'
	} else if (noOfTrades > 200) {
		noOfTradesShade = 'five'
	}
	let bg = 'red'
	if (cashInFlow > cashOutFlow) {
		bg = 'green'
	} else if (cashInFlow === cashOutFlow) {
		bg = 'yellow'
	}

	return `text-${bg}-shade-${noOfTradesShade}`
}

export const checkRouteArrayPermissions = routePaths => {
	const permissionList = getPermissionsFromStorage()
	if (permissionList && !ArrayUtil.isEmpty(routePaths)) {
		return routePaths.some(routePath => {
			if (routePath && routePath.permission && routePath.permission.length > 0) {
				const pageLevelPermissions = routePath.permission
				return pageLevelPermissions.some(el => permissionList.includes(el))
			}
			return false
		})
	} else {
		return false
	}
}

export const checkRoutePermission = obj => {
	const permissionList = getPermissionsFromStorage() || []
	const currentClientConfig = getStringFromStorage(activeClientConfig)
	if (obj && obj.permission && obj.permission.length > 0) {
		if (obj.hasOwnProperty('configSpecific')) {
			return obj.configSpecific.includes(currentClientConfig)
				? obj.permission && obj.permission.some(el => permissionList.includes(el))
				: false
		}
		return obj.permission && obj.permission.some(el => permissionList.includes(el))
	}
	return true
}

export const checkFeaturePermission = (permission: string) => {
	const permissionList = getPermissionsFromStorage()
	if (permissionList) {
		let isPermissionAvailable = permissionList.includes(permission)
		const permissionSplitArr = permission.split('_')
		// After config changes either READ or WRITE permissions (Read + Write) are available in token
		if (!isPermissionAvailable && permissionSplitArr[0] === 'R') {
			permissionSplitArr.shift() // Omit 1st word which is READ
			isPermissionAvailable = permissionList.includes(`RW_${permissionSplitArr.join('_')}`)
		}
		return isPermissionAvailable
	}
}

export const menuItemDisplay = result => {
	return (
		<>
			<span className='text-primary d-block f-14 font-weight-600'>{result.ticker}</span>
			<span className='f-12 text-grey-4 lh-normal d-block'>
				{result.longName}
				<br />
				ISIN:{result.isin}
				<br />
				CUSIP:{result.cusip}
			</span>
		</>
	)
}

export const dateErrorHandler = (tradeDate, settlementDate, maturityDate, terminationDate) => {
	let errorMessage = null
	if (terminationDate <= tradeDate || terminationDate <= settlementDate) {
		errorMessage = 'Termination date should be greater than Trade date and Settlement date.'
	}
	if (settlementDate < tradeDate) {
		errorMessage = 'Settlement date should be greater than Trade date.'
	}
	if (maturityDate <= tradeDate || maturityDate <= settlementDate) {
		errorMessage = 'Maturity date should be greater than Trade date and Settlement date.'
	}
	return errorMessage
}

export const addRestrictedStatus = (data: any, portfolioRestrictedEntries: any[] = []) => {
	const formattedData = Lodash.cloneDeep(data)
	formattedData &&
		formattedData.forEach((item: any) => {
			item.isRestricted =
				Array.isArray(portfolioRestrictedEntries) &&
				portfolioRestrictedEntries.map(item => item.securityId).includes(item.securityId)
					? 'Yes'
					: 'No'
		})
	return formattedData
}

export const getModifiedEntries = (data: any, portfolioRestrictedEntries) => {
	const modifiedEntries = Array.isArray(data)
		? Lodash.cloneDeep(data).map(item => {
				const flattenedItem = flattenRow(item)
				flattenedItem.disableCheckbox = ArrayUtil.isEmpty(flattenedItem.actions) || !Boolean(Number(item.quantity))
				flattenedItem.actions = ArrayUtil.isEmpty(flattenedItem.actions) ? '' : flattenedItem.actions[0]
				const inefficiencies = !ArrayUtil.isEmpty(item.inefficiencies) ? item.inefficiencies : []
				const inefficienciesAbbr = inefficiencies.map(val => val.abbr)
				flattenedItem[customColumnField.inefficienciesArray] = inefficienciesAbbr
				flattenedItem.isRestricted =
					Array.isArray(portfolioRestrictedEntries) &&
					portfolioRestrictedEntries.map(item => item.securityId).includes(item.securityId)
						? 'Yes'
						: 'No'
				flattenedItem.currency = flattenedItem.priceCurrency
				return flattenedItem
		  })
		: []
	return modifiedEntries
}

export const showBidStatus = (bidStatus: string) => {
	let bidStatusBadge = null
	const bidStatusLowerCase = FormatUtil.text.toLowerCase(bidStatus),
		bidStatusUpperCase = FormatUtil.text.toUpperCase(bidStatus)
	if (
		bidStatusLowerCase === ORDER_STATUS.ACCEPTED ||
		bidStatusLowerCase === ORDER_STATUS.CLOSED ||
		bidStatusLowerCase === ORDER_STATUS.BID_ACCEPTED
	) {
		bidStatusBadge = (
			<span className='badge bg-success f-12 badge-pill px-3 py-2 font-weight-400 text-white lh-10'>
				{bidStatusUpperCase}
			</span>
		)
	} else if (bidStatusLowerCase === ORDER_STATUS.CANCELLED || bidStatusLowerCase === ORDER_STATUS.BID_REJECTED) {
		bidStatusBadge = (
			<span className='badge bg-danger f-12 badge-pill px-3 py-2 font-weight-400 text-white lh-10'>
				{bidStatusUpperCase}
			</span>
		)
	} else if (bidStatusLowerCase === ORDER_STATUS.CREATED || bidStatusLowerCase === ORDER_STATUS.BID_APPLICABLE) {
		bidStatusBadge = (
			<span className='badge bg-light-blue f-12 badge-pill px-3 py-2 font-weight-400 text-white lh-10'>
				{bidStatusUpperCase}
			</span>
		)
	} else {
		bidStatusBadge = (
			<span className='badge bg-warning f-12 badge-pill px-3 py-2 font-weight-400 text-white lh-10'>
				{bidStatusUpperCase}
			</span>
		)
	}
	return bidStatusBadge
}

export const currencyMapping = (currency: string) => {
	return currency || ''
}

export const calculateBarColor = (chartType: string, value: any, category: any) => {
	let color = ''
	if (chartType === 'buy') {
		if (value > 0) {
			color = calculateAssetTypeBarColor(category)
		} else {
			color = '#F48888'
		}
	} else if (chartType === 'sell') {
		if (value > 0) {
			color = '#8BCC9E'
		} else {
			color = calculateAssetTypeBarColor(category)
		}
	} else if (!chartType) {
		if (value > 0) {
			color = '#8BCC9E'
		} else {
			color = '#F48888'
		}
	}
	return color
}

export const calculateAssetTypeBarColor = (category: any) => {
	const colorData = getObjectFromStorage('settlementLadderColorData')
	const colorItem = colorData.find(item => item.value === category)
	return colorItem.color
}

export const setColorDataInStorage = (data: any, groupOne: string, groupTwo: string) => {
	let uniqueData = {}
	data &&
		data.buys &&
		data.buys.entries.forEach(item => {
			uniqueData[item[groupTwo]] = 0
		})
	data &&
		data.sells &&
		data.sells.entries.forEach(item => {
			uniqueData[item[groupTwo]] = 0
		})
	const colorData = []
	Object.keys(uniqueData).forEach((item, index) => {
		const tempObj: any = {}
		tempObj.value = item
		tempObj.color = settlementLadderColors[index].color
		tempObj.className = settlementLadderColors[index].className
		colorData.push(tempObj)
	})
	setObjectInStorage('settlementLadderColorData', colorData)
}

export const configureSentryModule = (module: string) => {
	Sentry.configureScope(scope => {
		scope.setTag('Module', module)
	})
}

export const isHeaderActionButtonsEmpty = (headerActionButtons: any) => {
	const isEmpty = !headerActionButtons.some(item => {
		return item.length !== 0
	})
	return isEmpty
}

export const isValidFile = (filename: string) => {
	const validExtension = ['csv']
	const splittedFilename = filename.split('.')
	return splittedFilename.length === 2 && validExtension.includes(splittedFilename[1])
}

export const isValidAgreementTemplateFile = (filename: string) => {
	const validExtension = ['docx', 'doc', 'pdf']
	const splittedFilename = filename.split('.')
	return splittedFilename.length === 2 && validExtension.includes(splittedFilename[1])
}

export const getCounterPartyStatus = (entries: any) => {
	if (entries && entries.length > 0) {
		let status = ''
		const isActions = entries.some(item => item.actions.length > 0)
		let isCompleted = true
		entries.forEach(item => {
			if (!(item.status === 'ACCEPTED' || item.status === 'REJECTED')) {
				isCompleted = false
			}
		})
		if (isActions) {
			status = 'ACTION REQUIRED'
		} else if (isCompleted) {
			status = 'COMPLETED'
		} else {
			status = 'IN PROGRESS'
		}
		return status
	}
}

export const sortBasedOnActions = (entryOne, entryTwo, keyWord?: any) => {
	const entryOneMapping = actionTypeMapping[entryOne[keyWord]]
	const entryTwoMapping = actionTypeMapping[entryTwo[keyWord]]
	return entryOneMapping - entryTwoMapping
}

export const getRowIcon = (allocated: number, currentAvailableQuantity: number, isActions: boolean) => {
	let icon = null
	if (isActions) {
		if (allocated < currentAvailableQuantity) {
			icon = <i className='fa fa-long-arrow-up text-success pr-2'></i>
		} else if (allocated === currentAvailableQuantity) {
			icon = <i className='fa fa-arrow-up text-info pr-2'></i>
		} else if (allocated > currentAvailableQuantity) {
			icon = <i className='fa fa-long-arrow-down text-danger pr-2'></i>
		}
	}
	return icon
}

export const setAmChartLicense = (am4core: any) => {
	am4core.addLicense('CH229836622')
	return am4core
}

export const setFlexMonsterTheme = theme => {
	let cssUrl = 'https://cdn.flexmonster.com/theme/brightorange/flexmonster.min.css'
	if (theme && theme !== 'light') {
		cssUrl = 'https://cdn.flexmonster.com/theme/midnight/flexmonster.min.css'
	} else {
		cssUrl = 'https://cdn.flexmonster.com/theme/brightorange/flexmonster.min.css'
	}

	const prevThemeTags = getPrevTheme()
	const link = document.createElement('link')
	link.href = cssUrl
	link.rel = 'stylesheet'
	link.type = 'text/css'
	link.onload = () => {
		if (prevThemeTags !== null) {
			for (var i = 0; i < prevThemeTags.length; i++) {
				prevThemeTags[i].remove()
			}
		}
	}
	document.body.appendChild(link)
	setAgGridTheme()
}

export const getPrevTheme = () => {
	var linkTags = document.body.getElementsByTagName('link')
	var prevThemeTags = []
	for (let i = 0; i < linkTags.length; i++) {
		if (linkTags[i].href.indexOf('flexmonster.min.css') > -1 || linkTags[i].href.indexOf('flexmonster.css') > -1) {
			prevThemeTags.push(linkTags[i])
		}
	}
	linkTags = document.body.getElementsByTagName('link')
	for (let i = 0; i < linkTags.length; i++) {
		if (linkTags[i].href.indexOf('flexmonster.min.css') > -1 || linkTags[i].href.indexOf('flexmonster.css') > -1) {
			prevThemeTags.push(linkTags[i])
		}
	}
	return prevThemeTags
}

export const setAgGridTheme = () => {
	const theme = localStorage.getItem('theme') || 'light'

	var element = document.getElementById('ag-grid')
	if (element) {
		if (theme !== 'light') {
			element.classList.add('ag-theme-alpine-dark')
		} else {
			if (element.classList.contains('ag-theme-alpine-dark')) {
				element.classList.remove('ag-theme-alpine-dark')
			}
			element.classList.add('ag-theme-alpine')
		}
	}
}

export const getCsvData = tableData => {
	const csvData = []
	Array.isArray(tableData) &&
		tableData &&
		tableData.forEach(tableItem => {
			csvData.push(tableItem)
		})
	return csvData
}

export const getCsvHeader = columnDefinition => {
	const csvHeader = []
	!ArrayUtil.isEmpty(columnDefinition) &&
		columnDefinition.forEach(column => {
			if (column) {
				const header = {
					label: getHeaderValue(column),
					key: column.accessor,
				}
				csvHeader.push(header)
			}
		})
	return csvHeader
}

export const checkIsFiltered = filters => {
	let isFiltered = false
	isFiltered = Object.keys(filters).some(item => Boolean(filters[item].value) && filters[item].value !== 'View All')
	return isFiltered
}

export const getPivotTableRows = (id, activeMetric, defaultRow = []) => {
	if (activeMetric === pivotViews.notionalSource || activeMetric === pivotViews.notionalUse) {
		return activeMetric === pivotViews.notionalSource
			? [...defaultRow, 'Source Category']
			: [...defaultRow, 'Use Category']
	} else {
		if (id === 'ABCUser' || id === 'ABCSource') {
			return [...defaultRow, 'Source Counterparty', 'Source Category']
		} else if (id === 'SourceUseCategory') {
			return [...defaultRow, 'Source Category']
		}
	}
}

export const getPivotTableColumns = (id, activeMetric) => {
	if (activeMetric === pivotViews.notionalSource || activeMetric === pivotViews.notionalUse) {
		return []
	} else {
		if (id === 'ABCUser' || id === 'ABCSource') {
			return ['Use Counterparty', 'Use Category']
		} else if (id === 'SourceUseCategory') {
			return ['Use Category']
		}
	}
}

export const getPivotTableValueFilters = (id, activeMetric, routedFromValueFliter = {}) => {
	if (activeMetric === pivotViews.notionalSource || activeMetric === pivotViews.notionalUse) {
		return activeMetric === pivotViews.notionalSource
			? {
					'Source Category': {
						'To be Actioned': true,
					},
					...routedFromValueFliter,
			  }
			: {
					'Use Category': {
						Box: true,
					},
					...routedFromValueFliter,
			  }
	} else if (activeMetric === pivotViews.margin) {
		return {
			'Use Category': {
				'Firm Short': true,
				'Index Short': true,
				'Loan vs Cash': true,
				'Loan vs Noncash': true,
				'Pending Sell': true,
				Repo: true,
				Sell: true,
				'PB Short': true,
				'Unmapped Use': true,
				'Swap Short': true,
				Return: true,
				'Collateral Use': true,
			},
			...routedFromValueFliter,
		}
	} else {
		return {
			...routedFromValueFliter,
		}
	}
}

export const getPivotTableConfig = (originalConfig, metricConfig, metric) => {
	const pivotTableConfig = Lodash.cloneDeep(originalConfig)
	const isMetric = Object.keys(pivotMetrics).find(item => pivotMetrics[item] === metric)
	const isView = Object.keys(pivotViews).find(item => pivotViews[item] === metric)
	if (isMetric) {
		pivotTableConfig['metric'] = metricConfig
	} else {
		pivotTableConfig[isView] = metricConfig
	}
	return pivotTableConfig
}

export const filterView = view => {
	if (view === 'longTrades') {
		return 'SOURCE'
	} else if (view === 'shortTrades') {
		return 'USE'
	} else {
		return 'View All'
	}
}
export const setPivotTableStyles = () => {
	const pivotTableRows = Array.from(document.getElementsByClassName('pvtRowLabel'))
	const pivotTableColumns = Array.from(document.getElementsByClassName('pvtColLabel'))
	const toBeActionedRow: any = pivotTableRows.filter((item: any) => item.innerText === 'TO BE ACTIONED')
	const toBeActionedCol: any = pivotTableColumns.filter((item: any) => item.innerText === 'TO BE ACTIONED')
	pivotTableRows.forEach(item => {
		item.classList.remove('text-danger')
	})
	pivotTableColumns.forEach(item => {
		item.classList.remove('text-danger')
	})
	if (!ArrayUtil.isEmpty(toBeActionedRow)) {
		toBeActionedRow.forEach(item => {
			item.classList.add('text-danger')
		})
	}
	if (!ArrayUtil.isEmpty(toBeActionedCol)) {
		toBeActionedCol.forEach(item => {
			item.classList.add('text-danger')
		})
	}
}

export const getDefaultFilterValues = (history: any = {}, groupFilters: any, selectedView: any) => {
	const {selectedGroupOne, selectedGroupOneItem, selectedGroupTwo, selectedGroupTwoItem} = history
	const groupOneFieldDashboard = selectedGroupOne === 'notionalCurrency' ? 'currency' : selectedGroupOne
	const groupOneValueDashboard = selectedGroupOneItem
	const groupTwoFieldDashboard = selectedGroupTwo === 'notionalCurrency' ? 'currency' : selectedGroupTwo
	const groupTwoValueDashboard = selectedGroupTwoItem

	const groupOneFieldRedux = groupFilters && groupFilters.groupOne && groupFilters.groupOne.field
	const groupOneValueRedux = groupFilters && groupFilters.groupOne && groupFilters.groupOne.value
	const groupTwoFieldRedux = groupFilters && groupFilters.groupTwo && groupFilters.groupTwo.field
	const groupTwoValueRedux = groupFilters && groupFilters.groupTwo && groupFilters.groupTwo.value

	let groupOneField = groupOneFieldRedux
	let groupOneValue = groupOneValueRedux
	let groupTwoField = groupTwoFieldRedux
	let groupTwoValue = groupTwoValueRedux

	if (groupOneFieldDashboard || groupTwoFieldDashboard) {
		groupOneField = groupOneFieldDashboard
		groupOneValue = groupOneValueDashboard
		groupTwoField = groupTwoFieldDashboard
		groupTwoValue = groupTwoValueDashboard
	}

	const viewFieldDashboard = history && filterView(history.selectedView)
	const viewFieldRedux = selectedView

	const viewField = Lodash.isEmpty(history) ? viewFieldRedux : viewFieldDashboard
	return {
		groupOneField,
		groupOneValue,
		groupTwoField,
		groupTwoValue,
		viewField,
	}
}

export const formatFiltersToQuery = (filterData: any) => {
	const filters = Lodash.cloneDeep(filterData)
	const formattedFilters = []
	Object.keys(filters).forEach(item => {
		const {value} = filters[item]
		if (filters[item].field === 'fund') {
			filters[item].field = 'fundName'
		}
		if (value && value === 'N/A') {
			filters[item].value = toggleBlankValue(filters[item].value)
			formattedFilters.push(filters[item])
		} else if (value && value !== 'View All') {
			formattedFilters.push(filters[item])
		}
	})
	return formattedFilters
}

export const validateFilterField = memoizeOne(field => {
	return field === 'fund' ? 'fundName' : field
})

export const getDefaultPath = (activePath, path) => {
	let defaultPath = path === activePath
	if (activePath === paths.repoTest) {
		defaultPath = path === paths.repo
	} else if (activePath === paths.sblTest) {
		defaultPath = path === paths.sbl
	}
	return defaultPath
}

export const getCashAmount = item => {
	let cashAmount = item.cashAmount
	if (item.buySellInd === 'REPO' || item.buySellInd === 'LOAN') {
		cashAmount = -cashAmount
	}
	return cashAmount
}

export const addNumberFormatToColumnDefs = memoizeOne(data => {
	const columnDefs = Lodash.cloneDeep(data)
	return ArrayUtil.isEmpty(columnDefs)
		? []
		: columnDefs.map(col => {
				if (col.type === 'numericColumn') {
					return Object.assign({}, col, col.dataFormat === 'commaTwoDecimal' ? commaTwoDecimal : commaNoDecimal)
				}
				return col
		  })
}, isDeepEqual)

export const getThemeFromLs = () => {
	const defaultTheme = 'dark'
	return localStorage.getItem('theme') || defaultTheme
}

export function getShortenedId(itemID: string) {
	let id = itemID && itemID.trim()
	const ids = id ? id.split('-') : []
	const shortenedID = ids && ids.length > 0 ? ids[0] : ''
	return shortenedID
}

export const filterClientsBasedOnAgreementType = allClients => {
	return !ArrayUtil.isEmpty(allClients)
		? allClients.filter(client => {
				const agreementTypes = !ArrayUtil.isEmpty(client.agreementTypes)
					? client.agreementTypes.map(agtype => agtype.code)
					: []
				return agreementTypes.includes('MSLA') || agreementTypes.includes('GMSLA')
		  })
		: []
}
